var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "relative w-full h-full transition-colors duration-300",
      class: {
        "bg-transparent": _vm.step === 0,
        "bg-gray-rightside flex items-start": _vm.hasLeftSide,
        "bg-gray-proserv-search-box-placeholder": _vm.step === 3
      }
    },
    [
      _c("Sidebar", {
        attrs: {
          countries: _vm.countries,
          step: _vm.step,
          "selected-countries": _vm.selectedCountries,
          "selected-categories": _vm.selectedCategories,
          "selected-availabilities": _vm.selectedAvailabilities,
          "selected-products": _vm.selectedProducts
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          class: {
            "4xl:max-w-1600px px-4 sm:px-6 xl:px-8 3xl:px-10 4xl:px-0 mx-auto":
              _vm.step === 1,
            "w-full p-4 sm:p-6 lg:p-0": _vm.hasLeftSide,
            "w-full bg-gray-proserv-search-box-placeholder": _vm.step === 3
          },
          attrs: { id: "mainContent" }
        },
        [
          _c(
            "div",
            {
              staticClass: "steps-wrapper bg-transparent transition-colors",
              class: {
                "flex justify-between items-start": _vm.step !== 3,
                "flex-col space-y-4 sm:space-y-6 lg:space-y-0 lg:flex-row lg:p-4 xl:p-6 3xl:p-8 mb-4 sm:mb-6 lg:mb-0":
                  _vm.hasLeftSide,
                "bg-gray-proserv-steps-contact-us-bg py-6 4xl:py-8 shadow-md":
                  _vm.step === 3
              }
            },
            [
              _c("Steps", { attrs: { step: _vm.step } }),
              _vm._v(" "),
              _c("SearchBox", { attrs: { show: _vm.step === 1 } })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [
              _vm.step === 0
                ? _c("Location", {
                    attrs: {
                      countries: _vm.countries,
                      "new-selection": _vm.selectedCountries
                    }
                  })
                : _vm.step === 1
                ? _c("Results", {
                    attrs: {
                      filter: _vm.filter,
                      "new-selection": _vm.selectedProducts
                    }
                  })
                : _vm.step === 2
                ? _c("Selections", {
                    attrs: { selected: _vm.selectedProducts }
                  })
                : _c("ContactUs", {
                    attrs: {
                      step: 3,
                      selected: {
                        products: _vm.selectedProducts,
                        countries: _vm.selectedCountries
                      }
                    }
                  })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.step === 1
        ? _c("SelectedModal", { attrs: { name: "selections-modal" } })
        : _vm._e(),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-right" } }, [
        _vm.hasLeftSide
          ? _c(
              "button",
              {
                staticClass:
                  "flex justify-center items-center space-x-1 xl:space-x-2 w-32 xl:w-135px h-10 xl:h-53px fixed bottom-4 sm:bottom-6 lg:bottom-6 xl:bottom-8 2xl:bottom-10 right-4 sm:right-6 lg:right-6 xl:right-8 2xl:right-10 bg-gray-proserv-search-box-placeholder text-orange-proserv hover:bg-gray-700 shadow-md hover:shadow-lg rounded lg:rounded-lg transition",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.goStep(_vm.step + 1)
                  }
                }
              },
              [
                _c("span", { staticClass: "text-2xs" }, [_vm._v("CONTINUE")]),
                _vm._v(" "),
                _c(
                  "svg",
                  {
                    staticClass: "h-4.5 w-4.5",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      stroke: "currentColor"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        "stroke-width": "2",
                        d: "M9 5l7 7-7 7"
                      }
                    })
                  ]
                )
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }