//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SvgPanZoom from 'vue-svg-pan-zoom'
import map from '@svg-maps/world'
import TWEEN from '@tweenjs/tween.js'

export default {
  name: 'Maps',
  components: {
    SvgPanZoom,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    zoomTo: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      svgpanzoom: null,
      width: null,
      height: null,
      locations: [],
      zoomFactor: 0.8,
    }
  },
  computed: {
    selected () {
      return this.locations.filter(x => x.selected)
    },
    viewBox: () => map.viewBox,
    mapStyles: () => ({
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }),
  },
  watch: {
    visible: function (val) {
      if (val) {
        setTimeout(this.zoomBySelections, 250)
      }
    },
    zoomTo: function () {
      if (this.visible) {
        this.zoomBySelections()
      }
    },
  },
  methods: {
    registerMapToSvg (zoomLevel = 1) {
      let factor = zoomLevel > 1
        ? 1 / (this.zoomFactor * zoomLevel) : 1

      let strokeWidth = 0.5 * factor
      let countries = map.locations.map(x => ({
        id: x.id,
        title: x.name,
        fill: '#dddddd',
        stroke: '#cacaca',
        strokeWidth: strokeWidth + 'px',
        d: x.path,
        selected: false,
      }))
      countries.map(x => {
        const selected = this.zoomTo.includes(x.id.toLowerCase())
        x.fill = selected ? 'url(#SelectedGradient)' : '#dddddd'
        x.stroke = selected ? '#f7b41b' : '#cacaca'
        x.selected = selected
        return x
      })

      this.locations = countries
    },
    registerSvgPanZoom (svgpanzoom) {
      this.svgpanzoom = svgpanzoom
    },
    resetMap (resizing = false) {
      if (this.selected.length) {
        this.zoomBySelections(resizing)
      } else {
        this.svgpanzoom.resize()
        this.svgpanzoom.center()
      }
    },
    zoomBySelections (noAnimate = false) {
      this.registerMapToSvg()
      this.svgpanzoom.reset()
      const sizes = this.svgpanzoom.getSizes()

      if (this.selected.length) {
        let BBoxes = []
        this.selected.forEach(country => {
          const el = document.getElementById(country.id)
          if (el !== null) {
            BBoxes.push(el.getBBox())
          }
        })

        const minX = Math.min(...BBoxes.map(box => box.x)) * sizes.realZoom
        const maxX = Math.max(...BBoxes.map(box => box.x + box.width)) * sizes.realZoom
        const minY = Math.min(...BBoxes.map(box => box.y)) * sizes.realZoom
        const maxY = Math.max(...BBoxes.map(box => box.y + box.height)) * sizes.realZoom

        const realWidth = sizes.width
        const realHeight = sizes.height
        const areaWidth = maxX - minX
        const areaHeight = maxY - minY

        const x = ((realWidth / 2) - maxX) + (areaWidth / 2)
        const y = ((realHeight / 2) - maxY) + (areaHeight / 2)

        const scale = areaWidth > areaHeight
            ? realWidth / areaWidth
            : realHeight / areaHeight
        const scaleResult = areaWidth > areaHeight
            ? (scale > 0.5 ? scale - 0.5 : scale)
            : scale

        const animate = function (time) {
          TWEEN.update(time)
          requestAnimationFrame(animate)
        }
        const coords = {x: sizes.viewBox.x, y: sizes.viewBox.y}
        const zoom = {z: sizes.realZoom}
        const panTween = new TWEEN.Tween(coords)
          .to({x: x, y: y}, 500)
          .easing(TWEEN.Easing.Quadratic.In)
          .onUpdate(() => this.svgpanzoom.pan({ x: coords.x, y: coords.y }))
        const zoomTween = new TWEEN.Tween(zoom)
          .to({z: scaleResult}, 500)
          .easing(TWEEN.Easing.Quadratic.In)
          .onUpdate(() => this.svgpanzoom.zoom(zoom.z))
          .onComplete(() => {
            this.svgpanzoom.resize()
            this.svgpanzoom.updateBBox()
            // this.svgpanzoom.fit()
          })
          .delay(100)

        if (noAnimate) {
          this.svgpanzoom.pan({ x: x, y: y })
          this.svgpanzoom.zoomBy(scaleResult)
        } else {
          panTween.chain(zoomTween)
          panTween.start()
          requestAnimationFrame(animate)
        }

        this.registerMapToSvg(scaleResult)
      }
    },
    getWindowWidth () {
      if (typeof(window.innerWidth) == 'number') {
        // Non-IE
        return window.innerWidth
      } else if (document.documentElement && document.documentElement.clientWidth) {
        // IE 6+ in 'standards compliant mode'
        return document.documentElement.clientWidth
      } else if (document.body && document.body.clientWidth) {
        //IE 4 compatible
        return document.body.clientWidth
      }
      return 0
    },
    getWindowHeight () {
      if (typeof(window.innerHeight) == 'number') {
        // Non-IE
        return window.innerHeight
      } else if (document.documentElement && document.documentElement.clientHeight) {
        // IE 6+ in 'standards compliant mode'
        return document.documentElement.clientHeight
      } else if (document.body && document.body.clientHeight) {
        //IE 4 compatible
        return document.body.clientHeight
      }
      return 0
    },
    setViewBox (delay = 0, resizing = false) {
      let self = this
      let screenHeight = self.getWindowHeight()
      let headerHeight = document.getElementById('header').clientHeight
      self.height = screenHeight - headerHeight
      self.width = self.getWindowWidth()
      if (delay) {
        setTimeout(function () {
          self.resetMap(resizing)
        }, delay)
      } else {
        self.resetMap(resizing)
      }
    },
  },
  created () {
    let self = this
    self.registerMapToSvg()
    self.setViewBox(50)
    window.addEventListener('resize', function () {
      if (document.getElementById('maps') !== null) {
        self.setViewBox(50, true)
      }
    })
  },
}
