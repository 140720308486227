var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.filter.categories.length
        ? _c(
            "div",
            {
              staticClass:
                "flex justify-start items-start px-0 lg:px-4 xl:px-6 3xl:px-8 mb-4"
            },
            [
              _c(
                "span",
                {
                  staticClass:
                    "text-base text-1/2xs lg:text-xs xl:text-sm 2xl:text-base 3xl:text-lg text-gray-proserv-header-component lg:pt-1 mr-2 2xl:mr-3 4xl:mr-4"
                },
                [_vm._v("\n      FILTERS\n    ")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex flex-wrap justify-start items-center -mx-1 2xl:-mx-2 -my-1 2xl:-my-2"
                },
                _vm._l(_vm.filter.categories, function(category) {
                  return _c(
                    "span",
                    {
                      key: category.id,
                      staticClass:
                        "filter-item inline-flex items-center text-1/2xs lg:text-xs xl:text-sm 2xl:text-base 3xl:text-lg px-3 py-1 xl:px-4 xl:py-2 rounded-3xl mx-1 2xl:mx-2 my-1 2xl:my-2 max-w-45 xl:max-w-50 3xl:max-w-60 4xl:max-w-max"
                    },
                    [
                      _c("span", {
                        staticClass: "leading-none mr-2 xl:mr-3 truncate",
                        attrs: { title: category.name },
                        domProps: { innerHTML: _vm._s(category.name) }
                      }),
                      _vm._v(" "),
                      _c(
                        "svg",
                        {
                          staticClass:
                            "h-5 w-5 hover:text-gray-600 cursor-pointer flex-shrink-0",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 20 20",
                            fill: "currentColor"
                          },
                          on: {
                            click: function($event) {
                              return _vm.removeCategory(category)
                            }
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              "fill-rule": "evenodd",
                              d:
                                "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z",
                              "clip-rule": "evenodd"
                            }
                          })
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.preloading
        ? _c("div", { staticClass: "w-full my-5" }, [
            _c("div", { staticClass: "flex justify-center items-center" }, [
              _c(
                "svg",
                {
                  staticClass:
                    "animate-spin -ml-1 mr-3 h-6 w-6 text-gray-proserv-header-component",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24"
                  }
                },
                [
                  _c("circle", {
                    staticClass: "opacity-25",
                    attrs: {
                      cx: "12",
                      cy: "12",
                      r: "10",
                      stroke: "currentColor",
                      "stroke-width": "4"
                    }
                  }),
                  _vm._v(" "),
                  _c("path", {
                    staticClass: "opacity-75",
                    attrs: {
                      fill: "currentColor",
                      d:
                        "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    }
                  })
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "text-gray-proserv-header-component" },
                [_vm._v("Loading data...")]
              )
            ])
          ])
        : _vm.error
        ? _c("div", { staticClass: "w-full lg:text-center my-5" }, [
            _c("span", [
              _vm._v(
                "Woops! There is something wrong with our end. Please contact the site administrator."
              )
            ])
          ])
        : _vm.products.length == 0
        ? _c("div", { staticClass: "w-full lg:text-center my-5" }, [
            _c("span", [_vm._v("No results found.")])
          ])
        : [
            _c(
              "div",
              {
                staticClass:
                  "product-list w-full grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 4xl:grid-cols-5 gap-px -mx-px"
              },
              _vm._l(_vm.products, function(product) {
                return _c(
                  "div",
                  {
                    key: product.id,
                    staticClass:
                      "col-span-1 bg-gray-proserv-product-grid -m-px p-px"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "w-full h-full bg-gray-rightside border-r border-b border-gray-proserv-product-grid p-4 xl:p-6 3xl:p-8 m-px"
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "w-full h-full flex flex-col justify-between"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mb-4 xl:mb-6" },
                              [
                                _c("ProductImage", {
                                  staticClass:
                                    "mb-4 xl:mb-6 3xl:mb-8 h-44 lg:h-40 xl:h-42 2xl:h-44 3xl:h-48 4xl:h-52",
                                  attrs: {
                                    src:
                                      product.poster !== null
                                        ? product.poster
                                        : require("../../../../images/no-image.png"),
                                    alt: product.title,
                                    "logo-src": product.brand
                                      ? product.brand.logo
                                      : null,
                                    "logo-alt": product.brand
                                      ? product.brand.name
                                      : null
                                  }
                                }),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "title text-2sm font-extrabold mb-2 xl:mb-4",
                                  domProps: { innerHTML: _vm._s(product.title) }
                                }),
                                _vm._v(" "),
                                _c("p", {
                                  staticClass: "description text-xs",
                                  domProps: {
                                    innerHTML: _vm._s(product.excerpt)
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "block sm:hidden mb-2" },
                                  [
                                    product.attachment
                                      ? _c(
                                          "a",
                                          {
                                            staticClass:
                                              "flex items-center text-xs cursor-pointer hover:text-gray-600 transition",
                                            attrs: {
                                              href: product.attachment.url,
                                              target: "_blank"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mr-1" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    product.attachment.label
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "h-4 w-4",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  fill: "none",
                                                  viewBox: "0 0 24 24",
                                                  stroke: "currentColor"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d:
                                                      "M19 14l-7 7m0 0l-7-7m7 7V3"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "cta flex justify-start sm:justify-between items-center text-xs lg:text-1/2xs xl:text-xs"
                              },
                              [
                                _c("div", { staticClass: "form-checkbox" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selected,
                                        expression: "selected"
                                      }
                                    ],
                                    staticClass: "hidden",
                                    attrs: {
                                      id: "item" + product.id,
                                      type: "checkbox"
                                    },
                                    domProps: {
                                      value: product.id,
                                      checked: Array.isArray(_vm.selected)
                                        ? _vm._i(_vm.selected, product.id) > -1
                                        : _vm.selected
                                    },
                                    on: {
                                      change: [
                                        function($event) {
                                          var $$a = _vm.selected,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = product.id,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                (_vm.selected = $$a.concat([
                                                  $$v
                                                ]))
                                            } else {
                                              $$i > -1 &&
                                                (_vm.selected = $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1)))
                                            }
                                          } else {
                                            _vm.selected = $$c
                                          }
                                        },
                                        function($event) {
                                          return _vm.selectProduct()
                                        }
                                      ]
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "text-gray-proserv-search-box-placeholder flex items-center cursor-pointer select-none",
                                      attrs: { for: "item" + product.id }
                                    },
                                    [
                                      _c("span", {
                                        staticClass:
                                          "w-3 h-3 inline-block mr-1 xl:mr-2 border border-gray-proserv-search-box-placeholder"
                                      }),
                                      _vm._v(
                                        "\n                  SELECT\n                "
                                      )
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                product.attachment
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "sm:flex hidden justify-end items-center cursor-pointer hover:text-gray-600 transition",
                                        attrs: {
                                          href: product.attachment.url,
                                          target: "_blank"
                                        }
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "h-4 w-4 mr-0.5 xl:mr-1",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              fill: "none",
                                              viewBox: "0 0 24 24",
                                              stroke: "currentColor"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "stroke-width": "2",
                                                d: "M19 14l-7 7m0 0l-7-7m7 7V3"
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(product.attachment.label)
                                          )
                                        ])
                                      ]
                                    )
                                  : _c("div")
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "infinite-loading",
              {
                staticClass: "py-5",
                attrs: {
                  spinner: "spiral",
                  distance: 150,
                  identifier: _vm.infiniteId
                },
                on: { infinite: _vm.infiniteHandler }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "no-results" }, slot: "no-results" },
                  [_vm._v(" ")]
                ),
                _vm._v(" "),
                _c("div", { attrs: { slot: "no-more" }, slot: "no-more" }, [
                  _vm._v(" ")
                ])
              ]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }