var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "svg",
      {
        staticClass:
          "h-8 w-8 text-gray-proserv-header-component hover:text-gray-proserv-header-component-hover cursor-pointer",
        attrs: {
          id: "hamburgerMenuBtn",
          xmlns: "http://www.w3.org/2000/svg",
          fill: "none",
          viewBox: "0 0 24 24",
          stroke: "currentColor"
        },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.openMenu()
          }
        }
      },
      [
        _c("path", {
          attrs: {
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2",
            d: "M4 6h16M4 12h16M4 18h16"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }