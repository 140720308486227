import Vue from 'vue'
import VueTailWind from 'vue-tailwind'
import VueTailwindSettings from '../plugins/VueTailwindSettings'
import ProductPortal from '../components/ProductPortal'

Vue.use(VueTailWind, VueTailwindSettings)

export default {
  init() {
    new Vue({
      el: '#app',
      components: {
        ProductPortal,
      },
    })
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};
