var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "lg:relative", attrs: { id: "searchBox" } },
    [
      !_vm.isOpen
        ? _c(
            "span",
            {
              staticClass: "relative",
              staticStyle: { "z-index": "2" },
              on: {
                click: function($event) {
                  return _vm.openSearchBox()
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass:
                    "h-8 w-8 text-gray-proserv-header-component hover:text-gray-proserv-header-component-hover cursor-pointer",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    }
                  })
                ]
              )
            ]
          )
        : _c(
            "span",
            {
              staticClass: "relative",
              staticStyle: { "z-index": "2" },
              on: {
                click: function($event) {
                  return _vm.closeSearchBox()
                }
              }
            },
            [
              _c(
                "svg",
                {
                  staticClass:
                    "h-8 w-8 text-gray-proserv-header-component hover:text-gray-proserv-header-component-hover cursor-pointer",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    stroke: "currentColor"
                  }
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      "stroke-width": "2",
                      d: "M6 18L18 6M6 6l12 12"
                    }
                  })
                ]
              )
            ]
          ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "search-header" } }, [
        _c(
          "form",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isOpen,
                expression: "isOpen"
              }
            ],
            ref: "searchForm",
            staticClass:
              "search-form lg:w-64 xl:w-72 -mx-4 sm:-mx-6 absolute left-0 lg:left-14 top-11 md:top-14 lg:top-1/2 border-t border-gray-100 lg:border-none lg:transform lg:-translate-y-1/2 shadow-md lg:shadow-none z-10",
            attrs: {
              action: "https://www.proserv.com",
              method: "get",
              novalidate: ""
            }
          },
          [
            _c("input", {
              ref: "searchBox",
              staticClass:
                "w-full px-5 pr-12 sm:px-6 sm:pr-14 lg:px-4 pt-4 pb-4 lg:pr-12 text-gray-proserv-header-component-hover bg-white lg:bg-transparent lg:border-b-2 lg:border-gray-proserv-header-component outline-none focus:outline-none",
              attrs: { type: "text", name: "s", placeholder: "Search" },
              on: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.search()
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass:
                  "p-1 text-gray-proserv-header-component hover:text-gray-proserv-header-component-hover cursor-pointer outline-none focus:outline-none absolute right-5 sm:right-6 lg:right-4 top-1/2 transform -translate-y-1/2",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.search()
                  }
                }
              },
              [
                !_vm.loading
                  ? _c(
                      "svg",
                      {
                        staticClass: "h-5 w-5",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 20",
                          fill: "currentColor"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d:
                              "M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z",
                            "clip-rule": "evenodd"
                          }
                        })
                      ]
                    )
                  : _c(
                      "svg",
                      {
                        staticClass: "animate-spin h-5 w-5",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24"
                        }
                      },
                      [
                        _c("circle", {
                          staticClass: "opacity-25",
                          attrs: {
                            cx: "12",
                            cy: "12",
                            r: "10",
                            stroke: "currentColor",
                            "stroke-width": "4"
                          }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          staticClass: "opacity-75",
                          attrs: {
                            fill: "currentColor",
                            d:
                              "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          }
                        })
                      ]
                    )
              ]
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }