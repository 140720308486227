//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventBus from '@/util/event-bus'

export default {
  name: 'Steps',
  props: {
    step: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    jumpTo (n) {
      if (this.step > n) {
        EventBus.$emit('jumpToStep', n)
      }
    },
  },
}
