var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "t-modal",
    {
      attrs: { name: _vm.name, header: "My Selections" },
      on: { "before-open": _vm.onBeforeOpen }
    },
    [
      _vm.products.length
        ? _c(
            "div",
            {
              staticClass:
                "flex flex-col justify-start divide-y divide-gray-proserv-product-grid w-full"
            },
            _vm._l(_vm.products, function(product) {
              return _c(
                "div",
                {
                  key: product.id,
                  staticClass: "w-full flex space-x-8 px-0 py-4"
                },
                [
                  _c("div", { staticClass: "flex-shrink-0 w-24" }, [
                    _c("img", {
                      staticClass: "w-full h-auto",
                      attrs: {
                        src: product.poster
                          ? product.poster
                          : require("../../../../images/no-image.png")
                      }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "flex flex-col justify-between" }, [
                    _c("div", { staticClass: "mb-6 w-full" }, [
                      _c("span", {
                        staticClass:
                          "block text-2sm text-gray-proserv-header-component font-extrabold mb-4",
                        domProps: { innerHTML: _vm._s(product.title) }
                      }),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "text-xs",
                        staticStyle: { color: "#7b858d" },
                        domProps: { innerHTML: _vm._s(product.excerpt) }
                      })
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "w-full" }, [
                      _c(
                        "span",
                        {
                          staticClass:
                            "text-xs float-right cursor-pointer text-gray-proserv-header-component hover:text-red-700 transition",
                          on: {
                            click: function($event) {
                              return _vm.handleRemove(product)
                            }
                          }
                        },
                        [_vm._v("\n            REMOVE\n          ")]
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        : _c("div", { staticClass: "py-2 text-gray-700" }, [
            _vm._v("\n    There is no selected product yet.\n  ")
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }