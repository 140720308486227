//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SearchBox',
  data () {
    return {
      isOpen: false,
      loading: false,
    }
  },
  methods: {
    openSearchBox () {
      const searchBox = this.$refs.searchBox
      this.isOpen = true
      setTimeout(function () {
        searchBox.focus()
      }, 500)
    },
    closeSearchBox () {
      if (this.loading) return;
      this.isOpen = false
      this.$refs.searchBox.blur()
    },
    search () {
      this.loading = true
      this.$refs.searchForm.submit()
    },
  },
  created () {
    const self = this
    window.addEventListener('click', function (e) {
      if (document.getElementById('searchBox') && !document.getElementById('searchBox').contains(e.target)) {
        if (!self.loading) {
          self.isOpen = false
        }
      }
    })
  },
}
