//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $axios from '@/util/axios'
import EventBus from '@/util/event-bus'
import CollapseItem from './CollapseItem'

export default {
  name: 'Sidebar',
  components: {
    CollapseItem,
  },
  props: {
    step: {
      type: Number,
      default: 1,
    },
    countries: Array,
    selectedProducts: {
      type: Array,
      default: () => [],
    },
    selectedCountries: {
      type: Array,
      default: () => [],
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
    selectedCertifications: {
      type: Array,
      default: () => [],
    },
    selectedAvailabilities: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      searchQuery: '',
      isSearching: false,
      newSelectedCountries: [],
      categories: {
        loading: true,
        data: [],
        selected: [],
      },
      availabilities: {
        loading: true,
        data: [],
        selected: [],
      },
      certifications: {
        loading: true,
        data: [],
        selected: [],
      },
      regionsSelected: {
        loading: true,
        data: [],
      },
      selectedOptions: [],
    }
  },
  computed: {
    hasLeftSide () {
      return this.step === 1 || this.step === 2
    },
    filteredCountries () {
      if ((this.isSearching && this.searchQuery.length > 0) || this.searchQuery.length > 0) {
        return this.countries.filter(x => {
          return this.searchQuery.toLowerCase().split(' ').every(v => x.name.toLowerCase().includes(v))
        })
      } else {
        if (this.newSelectedCountries.length) {
          return this.countries.filter(x => this.newSelectedCountries.includes(x.code))
        } else {
          return []
        }
      }
    },
  },
  watch: {
    selectedCountries: function (val) {
      this.newSelectedCountries = val
    },
    selectedCategories: function (val) {
      this.categories.selected = val
    },
    selectedAvailabilities: function (val) {
      this.availabilities.selected = val
    },
    selectedCertifications: function (val) {
      this.certifications.selected = val
    },
    step: function (val) {
      if (val === 1) {
        this.loadCategories()
        this.loadCertifications()
        this.loadAvailabilities()
      }
      if (val === 2) {
        this.selectedRegionsHandler()
      }
    },
    searchQuery: function () {
      EventBus.$emit('resize_height')
    },

  },
  methods: {
    toggleSearching (bool) {
      const self = this
      if (bool) {
        self.isSearching = bool
      } else {
        setTimeout(function () {
          self.isSearching = bool
        }, 300)
      }
    },
    toggleSelectCountry (country) {
      this.searchQuery = ''
      if (this.selectedCountries.includes(country.code)) {
        this.newSelectedCountries = this.newSelectedCountries.filter(item => item !== country.code)
      } else {
        this.newSelectedCountries.push(country.code)
      }
      EventBus.$emit('newLocationSelected', { selected: this.newSelectedCountries })
    },
    toggleSelectCategory (category) {
      if (this.categories.selected.filter(item => item.id === category.id).length > 0) {
        this.categories.selected = this.categories.selected.filter(item => item.id !== category.id)
      } else {
        this.categories.selected.push(category)
      }
      EventBus.$emit('categorySelected', { selected: this.categories.selected })
    },
    toggleSelectAvailability (availability) {
      if (this.availabilities.selected.filter(item => item.id === availability.id).length > 0) {
        this.availabilities.selected = this.availabilities.selected.filter(item => item.id !== availability.id)
      } else {
        this.availabilities.selected.push(availability)
      }
      EventBus.$emit('availabilitySelected', { selected: this.availabilities.selected })
    },
    toggleSelectCertification (certification) {
      if (this.certifications.selected.filter(item => item.id === certification.id).length > 0) {
        this.certifications.selected = this.certifications.selected.filter(item => item.id !== certification.id)
      } else {
        this.certifications.selected.push(certification)
      }
      EventBus.$emit('certificationSelected', { selected: this.certifications.selected })
    },
    loadCategories () {
      $axios.get('categories').then(res => {
        this.categories.loading = false
        this.categories.data = res.data
      })
    },
    loadCertifications () {
      $axios.get('certifications').then(res => {
        this.certifications.loading = false
        this.certifications.data = res.data
      })
    },
    loadAvailabilities () {
      $axios.get('availabilities').then(res => {
        this.availabilities.loading = false
        this.availabilities.data = res.data
      })
    },
    backToStep (n) {
      this.searchQuery = ''
      EventBus.$emit('backToStep', n)
    },
    showSelectedModal () {
      EventBus.$emit('showSelectedModal')
    },
    closeSidebar () {
      document.getElementById('sidebar').classList.add('-translate-x-full')
      document.body.style.overflow = null
    },
    selectedRegionsHandler () {
      // reset loader in case they're hopping back from step 3 to 2 (already initialized to false from the get-go)
      this.regionsSelected.loading = true;

      const selectedProductsQueryReady = this.selectedProducts.map(x => x.id).join(','); // selectedProducts -> obj
      const selectedCountriesQueryReady = this.selectedCountries.join(','); // selectedCountries -> array

      $axios.get(`service_centers?product_ids=${selectedProductsQueryReady}&selected_countries=${selectedCountriesQueryReady}`).then(({ data }) => {
        const serviceCentersWithAgents = data;

        // setting loader to false for the sidebar loader. Doesn't affect other views.
        this.regionsSelected.loading = false
        // we're sending the data with the event handler
        // EventBus so we can access it with vue with this.$parent.regionsSelectedData for all the views we need to use
        // that data for.
        EventBus.$emit('regionsSelectedData', serviceCentersWithAgents)
      }).catch((error) => {
        console.error('Something went wrong with the fetching of data. getAllServiceCenters()', error)
      })
    },
  },
  created () {
    this.newSelectedCountries = this.selectedCountries;
    
    if( this.step == 1 ) {
        this.loadCategories()
        this.loadCertifications()
        this.loadAvailabilities()
    }
  },
}
