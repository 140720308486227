//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $axios from '@/util/axios'
import { formatBytes } from '@/util/helpers'
import VueHcaptcha from '@hcaptcha/vue-hcaptcha';

export default {
  name: 'ContactUs',
  props: {
    step: Number,
    selected: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    VueHcaptcha,
  },
  data () {
    return {
      siteKey: '2a4a2c60-4f99-485c-b241-25ff0e5d32f6',
      isShown: false,
      contact: {
        hpot: null,
        name: null,
        email: null,
        phone: null,
        company: null,
        details: null,
        h_captcha_response: null,
      },
      upload: null,
      loading: false,
      sent: false,
      errorStatus: null,
      errors: [],
    }
  },
  computed: {
    selectedCountries () {
      return this.selected.countries
    },
    selectedProducts () {
      return this.selected.products
    },
    formData () {
      let formData = new FormData()
      if (this.contact.hpot) formData.append('hpot', this.contact.hpot)
      if (this.contact.name) formData.append('name', this.contact.name)
      if (this.contact.email) formData.append('email', this.contact.email)
      if (this.contact.company) formData.append('company', this.contact.company)
      if (this.contact.phone) formData.append('phone', this.contact.phone)
      if (this.contact.details) formData.append('details', this.contact.details)
      if (this.contact.h_captcha_response) formData.append('h_captcha_response', this.contact.h_captcha_response)
      
      formData.append('selected_countries[]', 'none')
      
      if (this.selectedProducts.length) {
        for (let i = 0; i < this.selectedProducts.length; i++) {
          formData.append('products[]', this.selectedProducts[i].id)
        }
      }
      if (this.upload !== null) {
        formData.append('document', this.upload.file)
      }
      return formData
    },
  },
  methods: {
    triggerClickFileInput () {
      if (this.upload === null) {
        this.$refs.file.click()
      } else {
        this.$refs.file.value = null
        this.upload = null
      }
    },
    handleFileChange (e) {
      const file = e.target.files[0]
      // File should be no more than 10MB
      if (file.size / 1024 / 1024 > 10) {
        this.$refs.file.value = null
        this.upload = null
        return
      }
      this.upload = {}
      this.upload.file = file
      const ext = file.name.split('.').pop()
      const name = file.name.replace('.' + ext, '')
      if (name.length > 10) {
        this.upload.name = name.substr(0,10) + '...' + ext
      } else {
        this.upload.name = name+'.' + ext
      }
      this.upload.fullname = file.name
      this.upload.size = formatBytes(file.size)
    },
    submit () {
      this.loading = true
      $axios.post('contact', this.formData, {
        headers: { 'content-type': 'multipart/form-data' },
      }).then(() => {
        this.errors = [];
        this.sent = true;
        this.$refs.captcha.reset();
      }).catch(err => {
        this.loading = false
        this.errorStatus = err.response.status
        this.errors = err.response.status === 400
          ? err.response.data : [err.response.statusText]
        this.$refs.captcha.reset();
      })
    },
    onVerify(token, siteKey) {
      console.log(token)
      console.log(siteKey)
      this.contact.h_captcha_response = token;
    },
    onError() {
      this.contact.h_captcha_response = null;
    },
    onExpired() {
      this.contact.h_captcha_response = null;
    },
  },
  created () {
    const self = this
    setTimeout(function () {
      self.isShown = true
    }, 300)
  },
}
