//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventBus from '@/util/event-bus'
import $axios from '@/util/axios'
import { setHeight } from '@/util/style'
import Steps from './Steps'
import SearchBox from './SearchBox'
import Maps from './Maps'
import Sidebar from './Sidebar'
import Location from './Location'
import Results from './Results'
import Selections from './Selections'
import ContactUs from './ContactUs'
import SelectedModal from './SelectedModal'

export default {
  name: 'ProductPortal',
  components: {
    Steps,
    SearchBox,
    Maps,
    Sidebar,
    Location,
    Results,
    Selections,
    ContactUs,
    SelectedModal,
  },
  data () {
    return {
      countries: [],
      step: 1,
      searchQuery: null,
      withFilters: true,
      selectedProducts: [],
      selectedCountries: [],
      selectedCategories: [],
      selectedCertifications: [],
      selectedAvailabilities: [],
      regionsSelectedData: [],
    }
  },
  computed: {
    hasLeftSide () {
      return this.step === 1 || this.step === 2
    },
    filter () {
      return {
        q: this.searchQuery,
        countries: this.selectedCountries,
        categories: this.selectedCategories,
        certifications: this.selectedCertifications,
        availabilities: this.selectedAvailabilities,
        active: this.withFilters,
      }
    },
  },
  watch: {
    step: function (val) {
      this.searchQuery = null
      this.withFilters = true
      if (val === 1) {
        document.getElementById('content').classList.add('h-screen')
      } else {
        document.getElementById('content').classList.remove('h-screen')
      }
    },
  },
  methods: {
    goStep (n) {
      if (n === 1 && this.selectedProducts.length === 0) {
        alert('Your selection is empty. Please add at least a product to continue to the next step!')
        return
      }
      this.step = n
      window.scrollTo({top: 0, left: 0, behavior: 'instant'})
      setTimeout(function () {setHeight(n)}, 200)

      // add step-n class to body
      document.body.classList.add('step-'+n)
      // remove step classes from body
      let excludeSteps = [
        'step-1',
        'step-2',
        'step-3',
        'step-4',
      ].filter(cssClass => cssClass !== 'step-'+n)
      document.body.classList.remove(excludeSteps)
    },
    fetchCountries () {
      $axios.get('countries').then(({ data }) => this.countries = data)
    },
  },
  mounted () {
    document.body.classList.add('step-1')

    // this.fetchCountries()

    /* Location */
    EventBus.$on('locationSelected', ({ selected }) => {
      this.selectedCountries = selected.map(item => item.code)
    })
    EventBus.$on('locationNextStep', () => {
      this.goStep(2)
    })
    /* Sidebar */
    EventBus.$on('newLocationSelected', ({ selected }) => {
      this.selectedCountries = selected
    })
    EventBus.$on('categorySelected', ({ selected }) => {
      this.selectedCategories = selected
    })
    EventBus.$on('certificationSelected', ({ selected }) => {
      this.selectedCertifications = selected
    })
    EventBus.$on('availabilitySelected', ({ selected }) => {
      this.selectedAvailabilities = selected
    })
    /* Search Box */
    EventBus.$on('filterChanged', ({ q, withFilters }) => {
      this.searchQuery = q
      this.withFilters = withFilters
      setHeight(this.step)
    })
    /* Back to Step n */
    EventBus.$on('backToStep', n => this.goStep(n))
    EventBus.$on('jumpToStep', n => this.goStep(n))
    /* Results */
    EventBus.$on('filterCategoryRemoved', category => {
      this.selectedCategories = this.selectedCategories.filter(item => item.id !== category.id)
    })
    EventBus.$on('selectedProducts', ({ selected }) => {
      this.selectedProducts = selected
    })
    /* Selections/Cart */
    EventBus.$on('productRemoved', product => {
      this.selectedProducts = this.selectedProducts.filter(item => item.id !== product.id)
    })
    EventBus.$on('cartIsEmpty', () => {
      this.goStep(2)
      setTimeout(function () {
        alert('Your selection is empty. Please add at least a product to continue to the next step!')
      }, 750)
    })
    EventBus.$on('showSelectedModal', () => this.$modal.show('selections-modal', { selectedProducts: this.selectedProducts }))

    // get regions selected data from selectedRegionsHandler() in Sidebar\index.vue
    EventBus.$on('regionsSelectedData', (regionsSelectedData) => {
      this.regionsSelectedData = regionsSelectedData
    })

    let that = this
    setHeight(that.step)
    window.addEventListener('resize', function () {
      setHeight(that.step)
    })
  },
}
