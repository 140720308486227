//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { setHeight } from '@/util/style'
import EventBus from '@/util/event-bus'

export default {
  name: 'CollapseItem',
  props: {
    title: {
      type: String,
      default: '',
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      ref: this.toSnakeCase(this.title),
      isCollapsed: false,
    }
  },
  watch: {
    loading: function (val) {
      if (val) {
        this.setHeight()
      }
    },
  },
  methods: {
    toggleCollapse () {
      this.isCollapsed = !this.isCollapsed
      this.setHeight()
    },
    setHeight () {
      const scrollHeight = this.$refs[this.ref].scrollHeight
      this.$refs[this.ref].style.maxHeight = this.isCollapsed
        ? scrollHeight + 'px' : null
      this.$refs[this.ref].style.height = this.isCollapsed ? '100%' : 'auto'

      setTimeout(function () { setHeight(2) }, 500)
    },
    toSnakeCase (str) {
      return str.replace(/\W+/g, ' ')
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_')
    },
  },
  mounted () {
    this.isCollapsed = this.collapsed
    this.$nextTick(() => {
      if (this.isCollapsed) {
        this.setHeight()
      }
    })
    EventBus.$on('resize_height', () => {
      let that = this
      setTimeout(function () {
        that.setHeight()
      }, 500)
    })
  },
}
