//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventBus from '@/util/event-bus'
import $axios from '@/util/axios'
import { setHeight } from '@/util/style'
import InfiniteLoading from 'vue-infinite-loading'
import ProductImage from '../Image'

export default {
  name: 'Results',
  components: {
    InfiniteLoading,
    ProductImage,
  },
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
    newSelection: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      page: 1,
      totalPage: null,
      preloading: false,
      products: [],
      selected: [],
      infiniteId: +new Date(),
      error: false,
    }
  },
  computed: {
    searchParams () {
      let params = {}

      if (this.filter.q) {
        params = { ...params, q: this.filter.q }
      }

      if (this.filter.active) {
        if (this.filter.categories.length) {
          params = {
            ...params,
            cat: this.filter.categories.map(x => x.id).join(',')
          }
        }

        // if (this.filter.countries.length) {
        //   params = {
        //     ...params,
        //     countries: this.filter.countries.join(',')
        //   }
        // }

        if (this.filter.certifications.length) {
          params = {
            ...params,
            certs: this.filter.certifications.map(x => x.id).join(',')
          }
        }

        if (this.filter.availabilities.length) {
          params = {
            ...params,
            types: this.filter.availabilities.map(x => x.id).join(';')
          }
        }
      }

      return Object.keys(params).length ? new URLSearchParams(params) : ''
    },
  },
  watch: {
    filter: {
      handler: function () {
        this.products = [];
        this.page = 1;
        this.totalPage = null;
        this.infiniteId += 1;
        this.filterData();
      },
      deep: true,
    },
  },
  methods: {
    selectProduct () {
      const selected = this.products.filter(x => this.selected.includes(x.id))
      EventBus.$emit('selectedProducts', { selected: selected })
    },
    removeCategory (category) {
      EventBus.$emit('filterCategoryRemoved', category)
    },
    filterData (nextPage = false, state = null) {
    //   setHeight(3)
      let page = nextPage ? this.page + 1 : 1;
      if (!nextPage) {
        this.preloading = true
      }
      
      console.log(this.searchParams);
      
      $axios.get(`products?page=${page}&${this.searchParams}`).then(({ data }) => {
        
        if (nextPage) {
          this.page += 1;
          this.products.push(...data.hits);

          if (this.page >= data.last_page) {
            this.page = data.last_page;
            state.complete();
          } else {
            state.loaded();
          }
        } else {
          this.products = data.hits
        }

        this.totalPage = data.last_page;
        this.preloading = false
        setTimeout(function () { setHeight(3) }, 0)
      }).catch(() => {
        this.preloading = false
        if (state) {
          state.error()
        } else {
          this.error = true
        }
      })
    },
    infiniteHandler ($state) {
      if (this.totalPage === null || (this.totalPage !== null && this.page > this.totalPage)) return;
      this.filterData(true, $state)
    }
  },
  created () {
    this.selected = this.newSelection
    this.filterData()
  },
}
