//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventBus from '@/util/event-bus'
import vuescroll from 'vuescroll'

export default {
  name: 'Location',
  props: {
    countries: Array,
    newSelection: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    vuescroll,
  },
  data () {
    return {
      isOpen: false,
      searchQuery: null,
      loading: false,
      viewAll: false,
      selectedCountries: [],
      scrollOptions1: {
        vuescroll: {},
        scrollPanel: {
          scrollingX: false,
          easing: 'easeInOutQuad',
        },
        rail: {
          background: '#e4e7eb',
          opacity: 0.2,
          border: '1px solid #e4e7eb',
          size: '6px',
        },
        bar: {
          background: '#363a3d',
          opacity: 0.3,
          size: '4px',
          minSize: 0.1,
          keepShow: true,
        },
      },
      scrollOptions2: {
        vuescroll: {
          disabled: true,
        },
        scrollPanel: {
          scrollingX: false,
          easing: 'easeInOutQuad',
        },
        rail: {
          background: '#e4e7eb',
          opacity: 0.2,
          border: '1px solid #e4e7eb',
          size: '6px',
        },
        bar: {
          background: '#363a3d',
          opacity: 0.3,
          size: '4px',
          minSize: 0.1,
          keepShow: true,
        },
      },
    }
  },
  computed: {
    filteredCountries () {
      if (this.searchQuery) {
        return this.countries.filter(item => {
          return this.searchQuery.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v))
        })
      } else {
        return this.countries
      }
    },
    limitedSelectedCountries () {
      return this.viewAll ? this.selectedCountries : this.selectedCountries.slice(0, 3)
    },
  },
  methods: {
    toggleSelection (country) {
      if (this.selectedCountries.filter(item => item.code === country.code).length > 0) {
        this.selectedCountries = this.selectedCountries.filter(item => item.code != country.code)
      } else {
        this.selectedCountries.push(country)
      }
      this.isOpen = false
      this.searchQuery = null
      EventBus.$emit('locationSelected', { selected: this.selectedCountries })
    },
    toggleViewAll () {
      this.viewAll = !this.viewAll
      this.scrollOptions2.disabled = !this.viewAll
    },
    nextStep () {
      EventBus.$emit('locationNextStep')
    },
    onInput () {
      setTimeout(this.resizeBox, 200)
    },
    resizeBox () {
      const boxHeight = document.querySelector('.location-list').clientHeight
      const listHeight = document.querySelector('.all-list').scrollHeight

      if (boxHeight < listHeight) {
        document.querySelector('.location-list').style.maxHeight = null
      } else {
        document.querySelector('.location-list').style.maxHeight = listHeight + 'px'
      }
    },
  },
  created: function () {
    const self = this
    if (self.newSelection.length) {
      self.selectedCountries = self.countries.filter(item => self.newSelection.includes(item.code))
    }
    window.addEventListener('click', function (e) {
      if (document.getElementById('countrySearch') && !document.getElementById('countrySearch').contains(e.target)) {
        self.isOpen = false
      }
    })
  },
}
