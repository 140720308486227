var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass:
          "bg-white absolute top-0 right-0 bottom-0 left-0 w-full h-full -z-1 overflow-hidden",
        attrs: { id: "maps" }
      },
      [
        _c(
          "SvgPanZoom",
          {
            style: { width: _vm.width + "px", height: _vm.height + "px" },
            attrs: {
              zoomEnabled: false,
              fit: false,
              contain: true,
              center: true
            },
            on: { svgpanzoom: _vm.registerSvgPanZoom }
          },
          [
            _c(
              "svg",
              {
                ref: "map",
                staticClass: "w-full h-full",
                style: _vm.mapStyles,
                attrs: { "xml:space": "preserve" }
              },
              [
                _c(
                  "linearGradient",
                  {
                    attrs: {
                      id: "SelectedGradient",
                      gradientTransform: "rotate(90)"
                    }
                  },
                  [
                    _c("stop", {
                      attrs: { offset: "0%", "stop-color": "#e2a007" }
                    }),
                    _vm._v(" "),
                    _c("stop", {
                      attrs: { offset: "100%", "stop-color": "#f7b41b" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm._l(_vm.locations, function(region, index) {
                  return _c("path", {
                    key: index,
                    style: {
                      fill: region.fill,
                      stroke: region.stroke,
                      strokeWidth: region.strokeWidth,
                      strokeLinejoin: region.strokeLinejoin || "bevel"
                    },
                    attrs: { id: region.id, d: region.d }
                  })
                })
              ],
              2
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }