import TModal from 'vue-tailwind/dist/t-modal'

export default {
  't-modal': {
    component: TModal,
    props: {
      fixedClasses: {
        overlay: 'z-40 overflow-auto left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50',
        wrapper: 'z-50 relative mx-auto my-0 mt-12 px-2 xl:px-0',
        modal: 'bg-white shadow relative',
        body: 'p-4 overflow-auto',
        header: 'p-4 border-b sm:text-lg font-medium uppercase text-gray-700',
        footer: 'p-3 border-t',
        close: 'absolute right-0 top-0 m-3 text-gray-700 hover:text-gray-600 transition-250',
        closeIcon: 'h-5 w-5 fill-current',
        overlayEnterActiveClass: 'opacity-0 transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'transition ease-in opacity-100',
        overlayLeaveActiveClass: '',
        overlayLeaveToClass: 'opacity-0 duration-75',
      },
      classes: {
        overlay: 'bg-black',
        wrapper: 'max-w-xl',
        modal: 'rounded shadow-lg overflow-y-auto',
        body: 'text-base text-gray-700',
        header: '',
        footer: 'bg-gray-100',
        close: 'bg-blue-100 p-1 flex items-center justify-center leading-normal rounded-full text-blue-80 hover:bg-blue-200',
        closeIcon: '',
      },
    },
  },
}
