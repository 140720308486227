var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "transition duration-300",
      class: {
        "pt-8 sm:pt-14 md:pt-16 lg:pt-24 4xl:pt-32 inline-block":
          _vm.step === 1,
        "p-0": _vm.step !== 1,
        "bg-transparent 4xl:max-w-1600px 4xl:mx-auto px-4 sm:px-6 xl:px-8 3xl:px-10 4xl:px-0":
          _vm.step === 3
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "flex justify-start items-center rounded",
          class: {
            "text-xs md:text-md bg-white": _vm.step === 1,
            "text-xs md:text-1/2xs xl:text-md":
              _vm.step === 2 || _vm.step === 3,
            "text-xs md:text-md": _vm.step === 3
          }
        },
        [
          _c(
            "span",
            {
              staticClass:
                "steps-head p-2 leading-4 rounded transition duration-300",
              class: {
                "hover:bg-gray-proserv-header-component-hover hover:text-white cursor-pointer":
                  _vm.step > 1
              },
              on: {
                click: function($event) {
                  return _vm.jumpTo(1)
                }
              }
            },
            [_vm._v("\n      AFTERMARKET SERVICE PORTAL\n    ")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "py-2" }, [
            _c(
              "div",
              {
                staticClass:
                  "steps-nav flex justify-start items-center rounded divide-x divide-gray-proserv-steps-divider"
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "step-item px-2 lg:px-3 3xl:px-4 leading-4 transition duration-300",
                    class: {
                      active: _vm.step === 1,
                      clickable: _vm.step > 1
                    },
                    on: {
                      click: function($event) {
                        return _vm.jumpTo(1)
                      }
                    }
                  },
                  [
                    _vm._v("\n          STEP 1"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step === 1,
                            expression: "step === 1"
                          }
                        ]
                      },
                      [_vm._v(": PRODUCTS")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "step-item px-2 lg:px-3 3xl:px-4 leading-4 transition duration-300",
                    class: {
                      active: _vm.step === 2,
                      clickable: _vm.step > 2
                    },
                    on: {
                      click: function($event) {
                        return _vm.jumpTo(2)
                      }
                    }
                  },
                  [
                    _vm._v("\n          STEP 2"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step === 2,
                            expression: "step === 2"
                          }
                        ]
                      },
                      [_vm._v(": SELECTIONS")]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "step-item px-2 lg:px-3 3xl:px-4 leading-4 transition duration-300",
                    class: { active: _vm.step === 3 }
                  },
                  [
                    _vm._v("\n          STEP 3"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.step === 3,
                            expression: "step === 3"
                          }
                        ]
                      },
                      [_vm._v(": CONTACT US")]
                    )
                  ]
                )
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }