//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventBus from '@/util/event-bus'

export default {
  name: 'SelectedModal',
  props: {
    name: String,
  },
  data () {
    return {
      products: [],
    }
  },
  methods: {
    onBeforeOpen ({ params }) {
      this.products = [...params.selectedProducts]
    },
    handleRemove (product) {
      const confirmed = confirm('Are you sure to remove this product from selections?')
      if (confirmed) {
        this.doRemove(product)
      }
    },
    doRemove (product) {
      this.products = this.products.filter(x => x.id !== product.id)
      EventBus.$emit('productRemoved', product)
      if (this.products.length === 0) {
        this.$modal.hide(this.name)
        EventBus.$emit('cartIsEmpty')
      }
    },
  },
}
