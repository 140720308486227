//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProductImage',
  props: {
    src: String,
    alt: {
      type: String,
      default: 'image',
    },
    logoSrc: String,
    logoAlt: String,
  },
  data () {
    return {
      loading: true,
      error: false,
    }
  },
  methods: {
    init () {
      this.loading = false
    },
    initError () {
      this.error = true
    },
  },
}
