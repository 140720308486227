var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "border-b border-gray-proserv-sidebar-divider" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex justify-between items-start py-5 px-8 space-x-5 cursor-pointer",
          on: {
            click: function($event) {
              return _vm.toggleCollapse()
            }
          }
        },
        [
          _c("div", {
            staticClass: "text-base uppercase flex-grow",
            domProps: { innerHTML: _vm._s(_vm.title) }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "flex justify-end flex-shrink" }, [
            _c(
              "svg",
              {
                staticClass: "h-6 w-6 transition-transform transform",
                class: { "rotate-180": _vm.isCollapsed },
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  stroke: "currentColor"
                }
              },
              [
                _c("path", {
                  attrs: {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    "stroke-width": "2",
                    d: "M19 9l-7 7-7-7"
                  }
                })
              ]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: _vm.ref,
          staticClass: "overflow-hidden h-auto max-h-0 transition-all"
        },
        [
          _vm.loading
            ? _c(
                "div",
                { staticClass: "inline-flex items-center px-8 mb-4" },
                [
                  _c(
                    "svg",
                    {
                      staticClass: "animate-spin -ml-1 mr-3 h-5 w-5 text-white",
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        fill: "none",
                        viewBox: "0 0 24 24"
                      }
                    },
                    [
                      _c("circle", {
                        staticClass: "opacity-25",
                        attrs: {
                          cx: "12",
                          cy: "12",
                          r: "10",
                          stroke: "currentColor",
                          "stroke-width": "4"
                        }
                      }),
                      _vm._v(" "),
                      _c("path", {
                        staticClass: "opacity-75",
                        attrs: {
                          fill: "currentColor",
                          d:
                            "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        }
                      })
                    ]
                  ),
                  _vm._v(" "),
                  _vm._t("loading-text", function() {
                    return [_vm._v("Loading data...")]
                  })
                ],
                2
              )
            : _vm._t("content")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }