//
//
//
//
//
//
//
//

export default {
  name: 'HamburgerButton',
  methods: {
    openMenu () {
      document.getElementById('mainMenu').classList.add('active')
      document.getElementById('mainMenuOverlay').classList.add('active')
      setTimeout(() => {
        document.getElementsByClassName('side-nav-menu')[0].classList.add('active')
      }, 200);

      setTimeout(() => {
        document.getElementsByClassName('side-nav-menu')[1].classList.add('active')
      }, 450);
    },
  },
  created () {
    window.addEventListener('click', (e) => {
      if ((document.getElementById('mainMenu')
        && !document.getElementById('mainMenu').contains(e.target)
        && !document.getElementById('hamburgerMenuBtn').contains(e.target)
        ) || e.target.matches('#closeHamburgerBtn')
      ) {
        document.getElementById('mainMenu').classList.remove('active')
        document.getElementById('mainMenuOverlay').classList.remove('active')
        // there's only two side nav menus
        document.getElementsByClassName('side-nav-menu')[0].classList.remove('active')
        document.getElementsByClassName('side-nav-menu')[1].classList.remove('active')
      }
    })
  },
}
