import Vue from 'vue'
import HamburgerButton from '../components/HamburgerButton'
import SearchBox from '../components/SearchBox'

export default {
  init () {
    new Vue({
      el: '#header',
      components: {
        HamburgerButton,
        SearchBox,
      },
    })

    let that = this
    that.initBodyClass()
    window.addEventListener('resize', function () {
      that.initBodyClass()

      // check for sidebar visibility
      if (document.getElementById('sidebar') && !document.getElementById('sidebar').classList.contains('-translate-x-full')) {
        document.getElementById('sidebar').classList.add('-translate-x-full')
        document.body.style.overflow = 'hidden'
      }
    })
  },
  finalize () {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
  initBodyClass () {
    if (window.screen.width < 576) {
      document.body.classList.add('mobile-wide')
      document.body.classList.remove('tablet-wide')
      document.body.classList.remove('desktop-wide')
    } else if (window.screen.width < 992) {
      document.body.classList.remove('mobile-wide')
      document.body.classList.add('tablet-wide')
      document.body.classList.remove('desktop-wide')
    } else {
      document.body.classList.remove('mobile-wide')
      document.body.classList.remove('tablet-wide')
      document.body.classList.add('desktop-wide')
    }
  },
};
