var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide-right", mode: "in-out" } }, [
    _vm.show
      ? _c(
          "div",
          { staticClass: "w-full lg:w-auto flex justify-between items-start" },
          [
            _c(
              "div",
              { staticClass: "flex flex-col items-start lg:items-end" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchQuery,
                      expression: "searchQuery"
                    }
                  ],
                  staticClass:
                    "text-gray-700 bg-gray-proserv-search-box-bg placeholder-gray-proserv-search-box-placeholder outline-none focus:outline-none py-1.5 px-3 lg:py-2 xl:px-4 2xl:py-4 w-56 xl:w-64 2xl:w-72 3xl:w-80 mb-3",
                  attrs: { type: "text", placeholder: "Search" },
                  domProps: { value: _vm.searchQuery },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.searchQuery = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "search-options inline-flex justify-end items-center space-x-4 lg:space-x-2 xl:space-x-4 2xl:space-x-6 3xl:space-x-8"
                  },
                  [
                    _c("div", { staticClass: "checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.withFilters,
                            expression: "withFilters"
                          }
                        ],
                        staticClass: "hidden",
                        attrs: { id: "withFilters", type: "radio" },
                        domProps: {
                          value: true,
                          checked: _vm._q(_vm.withFilters, true)
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.withFilters = true
                            },
                            function($event) {
                              return _vm.filterChanged()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-gray-proserv-search-box-placeholder text-xs lg:text-1/2xs xl:text-xs flex items-center cursor-pointer select-none",
                          attrs: { for: "withFilters" }
                        },
                        [
                          _vm._v("\n            WITH FILTERS\n            "),
                          _c("span", {
                            staticClass:
                              "w-3 h-3 inline-block ml-1 xl:ml-2 border border-gray-proserv-search-box-placeholder"
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "checkbox" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.withFilters,
                            expression: "withFilters"
                          }
                        ],
                        staticClass: "hidden",
                        attrs: { id: "withoutFilters", type: "radio" },
                        domProps: {
                          value: false,
                          checked: _vm._q(_vm.withFilters, false)
                        },
                        on: {
                          change: [
                            function($event) {
                              _vm.withFilters = false
                            },
                            function($event) {
                              return _vm.filterChanged()
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass:
                            "text-gray-proserv-search-box-placeholder text-xs lg:text-1/2xs xl:text-xs flex items-center cursor-pointer select-none",
                          attrs: { for: "withoutFilters" }
                        },
                        [
                          _vm._v("\n            WITHOUT FILTERS\n            "),
                          _c("span", {
                            staticClass:
                              "w-3 h-3 inline-block ml-1 xl:ml-2 border border-gray-proserv-search-box-placeholder"
                          })
                        ]
                      )
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-end items-center space-x-2 lg:space-x-4 lg:hidden"
              },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-9 h-9 flex justify-center items-center bg-gray-proserv-search-box-placeholder text-orange-proserv hover:bg-gray-700 rounded",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.showSelectedModal()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "h-5 w-5",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 20",
                          fill: "currentColor"
                        }
                      },
                      [
                        _c("path", {
                          attrs: { d: "M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" }
                        }),
                        _vm._v(" "),
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d:
                              "M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z",
                            "clip-rule": "evenodd"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "w-9 h-9 flex justify-center items-center bg-gray-proserv-search-box-placeholder text-orange-proserv hover:bg-gray-700 rounded",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.showSidebar()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "h-5 w-5",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 20 20",
                          fill: "currentColor"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "fill-rule": "evenodd",
                            d:
                              "M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z",
                            "clip-rule": "evenodd"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            )
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }