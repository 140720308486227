//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventBus from '@/util/event-bus'
import $axios from '@/util/axios'
import ProductImage from '../Image'

export default {
  name: 'Selections',
  components: {
    ProductImage,
  },
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      relatedProducts: [],
      relatedLoading: false,
    }
  },
  watch: {
    selected: function (val) {
      if (val.length) {
        this.getRelatedProducts()
      }
    },
  },
  methods: {
    handleRemove (product) {
      const confirmed = confirm('Are you sure to remove this product from selections?')
      if (confirmed) {
        this.doRemove(product)
      }
    },
    doRemove (product) {
      EventBus.$emit('productRemoved', product)
      if (this.selected.length === 1) {
        EventBus.$emit('cartIsEmpty')
      }
    },
    addToCart (product) {
      let selected = [...this.selected, product]
      EventBus.$emit('selectedProducts', { selected: selected })
    },
    getRelatedProducts () {
      if (!this.selected.length) return;
      this.relatedLoading = true
      $axios.get(`related_products?id=${this.selected.map(x => x.id)}`).then(res => {
        this.relatedLoading = false
        this.relatedProducts = res.data
      })
    },
    showSidebar () {
      document.getElementById('sidebar').classList.remove('-translate-x-full')
      document.body.style.overflow = 'hidden'
    },
  },
  created () {
    this.getRelatedProducts();
  },
}
