var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-list w-full" }, [
    _c("div", { staticClass: "lg:hidden mb-4" }, [
      _c(
        "a",
        {
          staticClass:
            "text-2sm text-gray-proserv-header-component font-extrabold",
          attrs: { href: "javascript:;" },
          on: {
            click: function($event) {
              return _vm.showSidebar()
            }
          }
        },
        [_vm._v("\n      VIEW REGIONS SELECTED\n    ")]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "w-full flex flex-col lg:flex-row justify-start lg:space-x-4 xl:space-x-6 3xl:space-x-8 lg:px-4 xl:px-6 3xl:px-8 pb-8"
      },
      [
        _c(
          "div",
          { staticClass: "w-full lg:w-3/5 2xl:w-full mb-4 sm:mb-6 xl:mb-0" },
          [
            _c("div", {
              staticClass:
                "w-full h-1 lg:h-12 border-b border-gray-proserv-product-grid"
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "flex flex-col justify-start divide-y divide-gray-proserv-product-grid w-full border-b border-gray-proserv-product-grid"
              },
              _vm._l(_vm.selected, function(product) {
                return _c(
                  "div",
                  {
                    key: product.id,
                    staticClass:
                      "w-full flex flex-col sm:flex-row sm:space-x-4 md:space-x-6 lg:space-x-4 xl:space-x-6 3xl:space-x-8 space-y-4 sm:space-y-0 px-0 py-4"
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex-shrink-0 w-full sm:w-40 md:w-56 lg:w-40 xl:w-56 2xl:w-72 4xl:w-80 h-60 sm:h-40 md:h-40 lg:h-40 xl:h-40 2xl:h-44 4xl:h-64"
                      },
                      [
                        _c("ProductImage", {
                          staticClass: "w-full h-full",
                          attrs: {
                            src:
                              product.poster !== null
                                ? product.poster
                                : require("../../../../images/no-image.png"),
                            alt: product.title,
                            "logo-src": product.brand
                              ? product.brand.logo
                              : null,
                            "logo-alt": product.brand
                              ? product.brand.name
                              : null
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "flex flex-col justify-between" },
                      [
                        _c(
                          "div",
                          { staticClass: "mb-2 2xl:mb-6 lg:max-w-xs" },
                          [
                            _c("span", {
                              staticClass:
                                "block text-2sm lg:text-sm xl:text-2sm text-gray-proserv-header-component font-extrabold mb-2 2xl:mb-4",
                              domProps: { innerHTML: _vm._s(product.title) }
                            }),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "text-xs",
                              staticStyle: { color: "#7b858d" },
                              domProps: { innerHTML: _vm._s(product.excerpt) }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "w-full" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "text-xs float-right cursor-pointer text-gray-proserv-header-component hover:text-red-700 transition",
                              on: {
                                click: function($event) {
                                  return _vm.handleRemove(product)
                                }
                              }
                            },
                            [_vm._v("\n                REMOVE\n              ")]
                          )
                        ])
                      ]
                    )
                  ]
                )
              }),
              0
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex-shrink-0 w-full lg:w-2/5 2xl:w-sidebar" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm.relatedLoading
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "inline-flex items-center" },
                    [
                      _c(
                        "svg",
                        {
                          staticClass:
                            "animate-spin -ml-1 mr-3 h-5 w-5 text-gray-proserv-header-component",
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            fill: "none",
                            viewBox: "0 0 24 24"
                          }
                        },
                        [
                          _c("circle", {
                            staticClass: "opacity-25",
                            attrs: {
                              cx: "12",
                              cy: "12",
                              r: "10",
                              stroke: "currentColor",
                              "stroke-width": "4"
                            }
                          }),
                          _vm._v(" "),
                          _c("path", {
                            staticClass: "opacity-75",
                            attrs: {
                              fill: "currentColor",
                              d:
                                "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            }
                          })
                        ]
                      ),
                      _vm._v(" "),
                      _vm._t("loading-text", function() {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "text-gray-proserv-header-component"
                            },
                            [_vm._v("Loading data...")]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ])
              : _vm.relatedProducts.length
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-col justify-start divide-y divide-gray-proserv-product-grid w-full border-b border-gray-proserv-product-grid"
                  },
                  _vm._l(_vm.relatedProducts, function(product) {
                    return _c(
                      "div",
                      {
                        key: product.id,
                        staticClass:
                          "w-full flex flex-col sm:flex-row space-y-6 sm:space-y-0 sm:space-x-4 lg:space-x-2 xl:space-x-4 px-0 py-4"
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flex items-stretch flex-shrink-0" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-full sm:w-40 md:w-56 lg:w-28 xl:w-32 2xl:w-36 h-60 sm:h-40 md:h-40 lg:h-28 xl:h-32 2xl:h-36"
                              },
                              [
                                _c("ProductImage", {
                                  staticClass: "w-full h-full",
                                  attrs: {
                                    src:
                                      product.poster !== null
                                        ? product.poster
                                        : require("../../../../images/no-image.png"),
                                    alt: product.title,
                                    "logo-src": product.brand
                                      ? product.brand.logo
                                      : null,
                                    "logo-alt": product.brand
                                      ? product.brand.name
                                      : null
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex flex-col justify-between" },
                          [
                            _c("div", { staticClass: "mb-2 2xl:mb-4" }, [
                              _c("span", {
                                staticClass:
                                  "block text-2sm lg:text-sm xl:text-2sm text-gray-proserv-header-component font-extrabold mb-2 2xl:mb-4",
                                domProps: { innerHTML: _vm._s(product.title) }
                              }),
                              _vm._v(" "),
                              _c("p", {
                                staticClass: "text-xs mb-0",
                                staticStyle: { color: "#7b858d" },
                                domProps: { innerHTML: _vm._s(product.excerpt) }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-end lg:justify-start divide-x divide-gray-proserv-header-component text-xs lg:text-1/2xs 2xl:text-xs"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "cursor-pointer text-gray-proserv-header-component hover:text-red-700 transition py-px pr-3 lg:pr-1 2xl:pr-3",
                                    on: {
                                      click: function($event) {
                                        return _vm.addToCart(product)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                ADD TO SELECTIONS\n              "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "cursor-pointer text-gray-proserv-header-component hover:text-red-700 transition py-px pl-3 lg:pl-1 2xl:pl-3"
                                  },
                                  [
                                    _vm._v(
                                      "\n                VIEW PRODUCT\n              "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  0
                )
              : _c("div", { staticClass: "text-gray-700" }, [
                  _vm._v(
                    "\n        There is no related products from your selections.\n      "
                  )
                ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "flex items-center h-auto lg:h-12 pb-4 lg:pb-0 border-b border-gray-proserv-product-grid"
      },
      [
        _c(
          "span",
          {
            staticClass: "text-lg lg:text-base 2xl:text-lg",
            staticStyle: { color: "#5b6770" }
          },
          [_vm._v("\n          RELATED ITEMS\n        ")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }