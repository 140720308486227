var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "4xl:max-w-1600px grid grid-cols-1 lg:grid-cols-2 gap-y-4 lg:gap-y-0 gap-x-0 lg:gap-x-6 xl:gap-x-10 2xl:gap-x-20 3xl:gap-x-28 4xl:gap-x-36 px-4 sm:px-6 xl:px-8 3xl:px-10 4xl:px-0 py-8 lg:py-10 xl:py-12 2xl:py-14 3xl:py-16 4xl:py-20 mx-auto",
      attrs: { id: "contactUs" }
    },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.isShown
          ? _c("div", { staticClass: "w-full" }, [
              _c(
                "div",
                {
                  staticClass:
                    "w-full border-b pb-4 sm:pb-6 lg:pb-8 xl:pb-12 mb-4 sm:mb-6 lg:mb-8 xl:mb-12 border-none lg:border",
                  staticStyle: { color: "#c0c1c1", "border-color": "#898b8b" }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-lg xl:text-1.5xl 3xl:text-3xl 4xl:text-4xl mb-4 sm:mb-6 lg:mb-8 leading-tight"
                    },
                    [
                      _vm._v(
                        "\n          Fill out the form and you will receive  a summary of the information requested.\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticClass:
                        "text-lg xl:text-1.5xl 3xl:text-3xl 4xl:text-4xl mb-0 leading-tight"
                    },
                    [
                      _vm._v(
                        "\n          One of our representatives will also contact you.\n        "
                      )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "w-full block lg:hidden",
                  staticStyle: { "border-color": "#898b8b" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full bg-white p-4 lg:p-6 xl:p-8 3xl:p-10 4xl:p-12 mb-4 sm:mb-6"
                    },
                    [
                      _vm.errors.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 3xl:mb-5",
                              attrs: { role: "alert" }
                            },
                            [
                              _vm.errorStatus === 400
                                ? [
                                    _c(
                                      "p",
                                      { staticClass: "font-extrabold mb-1" },
                                      [_vm._v("There is error in your form:")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "ul",
                                      {
                                        staticClass:
                                          "list-disc list-inside pl-0"
                                      },
                                      _vm._l(_vm.errors, function(
                                        error,
                                        index
                                      ) {
                                        return _c("li", { key: index }, [
                                          _vm._v(_vm._s(error))
                                        ])
                                      }),
                                      0
                                    )
                                  ]
                                : [
                                    _c(
                                      "p",
                                      { staticClass: "font-extrabold mb-1" },
                                      [_vm._v(_vm._s(_vm.error[0]))]
                                    ),
                                    _vm._v(" "),
                                    _c("div", [
                                      _vm._v(
                                        "\n                There is something wrong with your request. Please, try again! If the error still persists, please contact our site administrator.\n              "
                                      )
                                    ])
                                  ]
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.sent
                        ? _c("form", [
                            _c("div", { staticClass: "absolute opacity-0" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.contact.hpot,
                                    expression: "contact.hpot"
                                  }
                                ],
                                attrs: { type: "text" },
                                domProps: { value: _vm.contact.hpot },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.contact,
                                      "hpot",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-form w-full mb-4 3xl:mb-5 relative"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.contact.name,
                                      expression: "contact.name"
                                    }
                                  ],
                                  staticClass:
                                    "w-full h-15 pt-2 px-4 3xl:px-5 text-lg text-gray-700 placeholder-gray-proserv-header-component border-2 outline-none focus:outline-none",
                                  staticStyle: { "border-color": "#878282" },
                                  attrs: { type: "text", maxlength: "150" },
                                  domProps: { value: _vm.contact.name },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.contact,
                                        "name",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "floating-label absolute left-4 3xl:left-5 top-1/2 transform -translate-y-1/2 text-lg text-gray-700 transition-all pointer-events-none",
                                    class: { filled: _vm.contact.name }
                                  },
                                  [
                                    _vm._v("\n                NAME "),
                                    _c(
                                      "span",
                                      { staticClass: "text-red-400 ml-1" },
                                      [_vm._v("*")]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-form w-full mb-4 3xl:mb-5 relative"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.contact.email,
                                      expression: "contact.email"
                                    }
                                  ],
                                  staticClass:
                                    "w-full h-15 pt-2 px-4 3xl:px-5 text-lg text-gray-700 placeholder-gray-proserv-header-component border-2 outline-none focus:outline-none",
                                  staticStyle: { "border-color": "#878282" },
                                  attrs: { type: "email", maxlength: "150" },
                                  domProps: { value: _vm.contact.email },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.contact,
                                        "email",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "floating-label absolute left-4 3xl:left-5 top-1/2 transform -translate-y-1/2 text-lg text-gray-700 transition-all pointer-events-none",
                                    class: { filled: _vm.contact.email }
                                  },
                                  [
                                    _vm._v("\n                EMAIL "),
                                    _c(
                                      "span",
                                      { staticClass: "text-red-400 ml-1" },
                                      [_vm._v("*")]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-form w-full mb-4 3xl:mb-5 relative"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.contact.company,
                                      expression: "contact.company"
                                    }
                                  ],
                                  staticClass:
                                    "w-full h-15 pt-2 px-4 3xl:px-5 text-lg text-gray-700 placeholder-gray-proserv-header-component border-2 outline-none focus:outline-none",
                                  staticStyle: { "border-color": "#878282" },
                                  attrs: { type: "text", maxlength: "200" },
                                  domProps: { value: _vm.contact.company },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.contact,
                                        "company",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "floating-label absolute left-4 3xl:left-5 top-1/2 transform -translate-y-1/2 text-lg text-gray-700 transition-all pointer-events-none",
                                    class: { filled: _vm.contact.company }
                                  },
                                  [
                                    _vm._v("\n                COMPANY "),
                                    _c(
                                      "span",
                                      { staticClass: "text-red-400 ml-1" },
                                      [_vm._v("*")]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-form w-full mb-4 3xl:mb-5 relative"
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.contact.phone,
                                      expression: "contact.phone"
                                    }
                                  ],
                                  staticClass:
                                    "w-full h-15 pt-2 px-4 3xl:px-5 text-lg text-gray-700 placeholder-gray-proserv-header-component border-2 outline-none focus:outline-none",
                                  staticStyle: { "border-color": "#878282" },
                                  attrs: { type: "tel", maxlength: "25" },
                                  domProps: { value: _vm.contact.phone },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.contact,
                                        "phone",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "floating-label absolute left-4 3xl:left-5 top-1/2 transform -translate-y-1/2 text-lg text-gray-700 transition-all pointer-events-none",
                                    class: { filled: _vm.contact.phone }
                                  },
                                  [_vm._v("PHONE")]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "input-form w-full mb-4 3xl:mb-5 relative"
                              },
                              [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.contact.details,
                                      expression: "contact.details"
                                    }
                                  ],
                                  staticClass:
                                    "w-full h-44 p-4 3xl:p-5 text-lg text-gray-700 placeholder-gray-proserv-header-component outline-none focus:outline-none resize-y",
                                  staticStyle: {
                                    "background-color": "#e4e3e3"
                                  },
                                  attrs: { maxlength: "1000" },
                                  domProps: { value: _vm.contact.details },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.contact,
                                        "details",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "floating-label absolute left-4 3xl:left-5 top-4 text-lg text-gray-700 transition-all pointer-events-none",
                                    class: { filled: _vm.contact.details }
                                  },
                                  [
                                    _vm._v(
                                      "\n                JOB / PROJECT DETAILS "
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "text-red-400 ml-1" },
                                      [_vm._v("*")]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "mb-6" },
                              [
                                _c("VueHcaptcha", {
                                  ref: "captcha",
                                  attrs: { sitekey: _vm.siteKey },
                                  on: {
                                    verify: _vm.onVerify,
                                    expired: _vm.onExpired,
                                    error: _vm.onError
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "mb-6" }, [
                              _vm._v(
                                "\n              Proserv will use this information to deal with your enquiry. For more information see our "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-orange-proserv hover:underline",
                                  attrs: {
                                    href:
                                      "https://www.proserv.com/privacy-policy",
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Privacy Notice")]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "flex justify-between items-center"
                              },
                              [
                                _c("input", {
                                  ref: "file",
                                  staticClass: "hidden",
                                  attrs: {
                                    type: "file",
                                    accept:
                                      "application/msword,.doc, .docx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,application/pdf,.pdf"
                                  },
                                  on: { change: _vm.handleFileChange }
                                }),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn-upload w-48 h-13 border-2 border-gray-proserv-upload-border hover:shadow-lg transition",
                                    class: {
                                      "flex flex-col justify-center items-center hover:bg-gray-200":
                                        _vm.upload === null,
                                      "flex items-center relative overflow-hidden bg-orange-proserv text-white hover:border-red-600":
                                        _vm.upload !== null
                                    },
                                    staticStyle: { color: "#303539" },
                                    attrs: {
                                      type: "button",
                                      title:
                                        _vm.upload !== null
                                          ? _vm.upload.fullname
                                          : ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.triggerClickFileInput()
                                      }
                                    }
                                  },
                                  [
                                    _vm.upload === null
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-md leading-none mb-0.5"
                                            },
                                            [_vm._v("UPLOAD RFQ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "text-1/2xs" },
                                            [_vm._v("10 MB MAX")]
                                          )
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fileinfo w-full h-full flex flex-col justify-center items-center transform translate-x-0 transition-transform duration-300"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "text-md leading-none mb-0.5"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.upload.name)
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "text-1/2xs" },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.upload.size)
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "fileremove absolute top-0 left-0 w-full h-full text-lg leading-none flex justify-center items-center transform -translate-x-60 transition-transform duration-300 bg-red-600 text-white"
                                            },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  staticClass:
                                                    "h-4.5 w-4.5 mr-1",
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    fill: "none",
                                                    viewBox: "0 0 24 24",
                                                    stroke: "currentColor"
                                                  }
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin":
                                                        "round",
                                                      "stroke-width": "2",
                                                      d:
                                                        "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                    }
                                                  })
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v("Remove File")
                                              ])
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "w-48 h-13 text-orange-proserv bg-gray-proserv-search-box-placeholder transition",
                                    class: {
                                      "cursor-not-allowed": _vm.loading,
                                      "hover:bg-gray-proserv-sidebar-search-bg hover:shadow-lg": !_vm.loading
                                    },
                                    attrs: {
                                      type: "button",
                                      disabled: _vm.loading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.submit()
                                      }
                                    }
                                  },
                                  [
                                    !_vm.loading
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-full h-full flex justify-center items-center"
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "text-md mr-1" },
                                              [_vm._v("SEND MESSAGE")]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "h-4 w-4",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  fill: "none",
                                                  viewBox: "0 0 24 24",
                                                  stroke: "currentColor"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d: "M9 5l7 7-7 7"
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-full h-full flex justify-center items-center"
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass:
                                                  "animate-spin -ml-1 mr-2 h-4.5 w-4.5 text-white",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  fill: "none",
                                                  viewBox: "0 0 24 24"
                                                }
                                              },
                                              [
                                                _c("circle", {
                                                  staticClass: "opacity-25",
                                                  attrs: {
                                                    cx: "12",
                                                    cy: "12",
                                                    r: "10",
                                                    stroke: "currentColor",
                                                    "stroke-width": "4"
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("path", {
                                                  staticClass: "opacity-75",
                                                  attrs: {
                                                    fill: "currentColor",
                                                    d:
                                                      "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "text-lg" },
                                              [_vm._v("SUBMITTING")]
                                            )
                                          ]
                                        )
                                  ]
                                )
                              ]
                            )
                          ])
                        : _c(
                            "div",
                            {
                              staticClass:
                                "w-full text-gray-proserv-selected-countries-text text-3xl text-center py-20 leading-normal"
                            },
                            [
                              _vm._v(
                                "\n            Your message has successfully sent!"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            One of our sales representatives listed below will be contacting you shortly regarding your recent inquiry.\n          "
                              )
                            ]
                          )
                    ]
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "w-full border-b pb-4 sm:pb-6 lg:pb-8 xl:pb-12 mb-4 sm:mb-6 lg:mb-8 xl:mb-12",
                  staticStyle: { "border-color": "#898b8b" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "font-light text-lg xl:text-1.5xl 3xl:text-3xl 4xl:text-4xl text-white mb-4 xl:mb-6"
                    },
                    [
                      _vm._v(
                        "\n          Summary of your selections:\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-cols-12 gap-2 md:gap-y-4 xxl:gap-y-6 md:gap-x-8 2xl:gap-x-10",
                      staticStyle: { color: "#8f8f90" }
                    },
                    _vm._l(_vm.selected.products, function(product) {
                      return _c(
                        "div",
                        {
                          key: product.id,
                          staticClass:
                            "col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-6"
                        },
                        [
                          _c("span", {
                            staticClass:
                              "text-orange-proserv-text text-md lg:text-base 2xl:text-lg 4xl:text-xl",
                            domProps: { innerHTML: _vm._s(product.title) }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "w-full pb-4 lg:pb-6" }, [
                _c(
                  "div",
                  {
                    staticClass: "grid grid-cols-12 gap-8 2xl:gap-10 text-xl",
                    staticStyle: { color: "#8f8f90" }
                  },
                  _vm._l(this.$parent.regionsSelectedData, function(
                    region,
                    key
                  ) {
                    return _c(
                      "div",
                      {
                        key: key,
                        staticClass:
                          "col-span-12 sm:col-span-6 md:col-span-4 lg:col-span-6"
                      },
                      [
                        _c("span", {
                          staticClass:
                            "block text-lg xl:text-xl 3xl:text-1.5xl mb-2 md:mb-3",
                          domProps: { innerHTML: _vm._s(region.name) }
                        }),
                        _vm._v(" "),
                        _vm._l(region.agents, function(agent) {
                          return _c(
                            "div",
                            { key: agent.id, staticClass: "space-y-4" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "text-base text-white mb-2 md:mb-3"
                                },
                                [
                                  _c("span", {
                                    domProps: { innerHTML: _vm._s(agent.name) }
                                  }),
                                  _vm._v(" | "),
                                  _c("span", {
                                    domProps: { innerHTML: _vm._s(agent.job) }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "text-base" }, [
                                _c("span", [_vm._v(_vm._s(agent.phone))])
                              ])
                            ]
                          )
                        })
                      ],
                      2
                    )
                  }),
                  0
                )
              ])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide-right" } }, [
        _vm.isShown
          ? _c("div", { staticClass: "w-full hidden lg:block" }, [
              _c(
                "div",
                {
                  staticClass:
                    "w-full bg-white p-4 lg:p-6 xl:p-8 3xl:p-10 4xl:p-12 relative"
                },
                [
                  _vm.errors.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4 3xl:mb-5",
                          attrs: { role: "alert" }
                        },
                        [
                          _vm.errorStatus === 400
                            ? [
                                _c(
                                  "p",
                                  { staticClass: "font-extrabold mb-1" },
                                  [_vm._v("There is error in your form:")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "ul",
                                  { staticClass: "list-disc list-inside pl-0" },
                                  _vm._l(_vm.errors, function(error, index) {
                                    return _c("li", { key: index }, [
                                      _vm._v(_vm._s(error))
                                    ])
                                  }),
                                  0
                                )
                              ]
                            : [
                                _c(
                                  "p",
                                  { staticClass: "font-extrabold mb-1" },
                                  [_vm._v(_vm._s(_vm.error[0]))]
                                ),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n              There is something wrong with your request. Please, try again! If the error still persists, please contact our site administrator.\n            "
                                  )
                                ])
                              ]
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.sent
                    ? _c("form", [
                        _c("div", { staticClass: "absolute opacity-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.contact.hpot,
                                expression: "contact.hpot"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.contact.hpot },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.contact,
                                  "hpot",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-form w-full mb-4 3xl:mb-5 relative"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.name,
                                  expression: "contact.name"
                                }
                              ],
                              staticClass:
                                "w-full h-15 pt-2 px-4 3xl:px-5 text-lg text-gray-700 placeholder-gray-proserv-header-component border-2 outline-none focus:outline-none",
                              staticStyle: { "border-color": "#878282" },
                              attrs: { type: "text", maxlength: "150" },
                              domProps: { value: _vm.contact.name },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact,
                                    "name",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "floating-label absolute left-4 3xl:left-5 top-1/2 transform -translate-y-1/2 text-lg text-gray-700 transition-all pointer-events-none",
                                class: { filled: _vm.contact.name }
                              },
                              [
                                _vm._v("\n              NAME "),
                                _c(
                                  "span",
                                  { staticClass: "text-red-400 ml-1" },
                                  [_vm._v("*")]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-form w-full mb-4 3xl:mb-5 relative"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.email,
                                  expression: "contact.email"
                                }
                              ],
                              staticClass:
                                "w-full h-15 pt-2 px-4 3xl:px-5 text-lg text-gray-700 placeholder-gray-proserv-header-component border-2 outline-none focus:outline-none",
                              staticStyle: { "border-color": "#878282" },
                              attrs: { type: "email", maxlength: "150" },
                              domProps: { value: _vm.contact.email },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact,
                                    "email",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "floating-label absolute left-4 3xl:left-5 top-1/2 transform -translate-y-1/2 text-lg text-gray-700 transition-all pointer-events-none",
                                class: { filled: _vm.contact.email }
                              },
                              [
                                _vm._v("\n              EMAIL "),
                                _c(
                                  "span",
                                  { staticClass: "text-red-400 ml-1" },
                                  [_vm._v("*")]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-form w-full mb-4 3xl:mb-5 relative"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.company,
                                  expression: "contact.company"
                                }
                              ],
                              staticClass:
                                "w-full h-15 pt-2 px-4 3xl:px-5 text-lg text-gray-700 placeholder-gray-proserv-header-component border-2 outline-none focus:outline-none",
                              staticStyle: { "border-color": "#878282" },
                              attrs: { type: "text", maxlength: "200" },
                              domProps: { value: _vm.contact.company },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact,
                                    "company",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "floating-label absolute left-4 3xl:left-5 top-1/2 transform -translate-y-1/2 text-lg text-gray-700 transition-all pointer-events-none",
                                class: { filled: _vm.contact.company }
                              },
                              [
                                _vm._v("\n              COMPANY "),
                                _c(
                                  "span",
                                  { staticClass: "text-red-400 ml-1" },
                                  [_vm._v("*")]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-form w-full mb-4 3xl:mb-5 relative"
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.phone,
                                  expression: "contact.phone"
                                }
                              ],
                              staticClass:
                                "w-full h-15 pt-2 px-4 3xl:px-5 text-lg text-gray-700 placeholder-gray-proserv-header-component border-2 outline-none focus:outline-none",
                              staticStyle: { "border-color": "#878282" },
                              attrs: { type: "tel", maxlength: "25" },
                              domProps: { value: _vm.contact.phone },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact,
                                    "phone",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "floating-label absolute left-4 3xl:left-5 top-1/2 transform -translate-y-1/2 text-lg text-gray-700 transition-all pointer-events-none",
                                class: { filled: _vm.contact.phone }
                              },
                              [_vm._v("PHONE")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-form w-full mb-4 3xl:mb-5 relative"
                          },
                          [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.contact.details,
                                  expression: "contact.details"
                                }
                              ],
                              staticClass:
                                "w-full h-44 p-4 3xl:p-5 text-lg text-gray-700 placeholder-gray-proserv-header-component outline-none focus:outline-none resize-y",
                              staticStyle: { "background-color": "#e4e3e3" },
                              attrs: { maxlength: "1000" },
                              domProps: { value: _vm.contact.details },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.contact,
                                    "details",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "floating-label absolute left-4 3xl:left-5 top-4 text-lg text-gray-700 transition-all pointer-events-none",
                                class: { filled: _vm.contact.details }
                              },
                              [
                                _vm._v(
                                  "\n              JOB / PROJECT DETAILS\n              "
                                ),
                                _c(
                                  "span",
                                  { staticClass: "text-red-400 ml-1" },
                                  [_vm._v("*")]
                                )
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "mb-6" },
                          [
                            _c("VueHcaptcha", {
                              ref: "captcha",
                              attrs: { sitekey: _vm.siteKey },
                              on: {
                                verify: _vm.onVerify,
                                expired: _vm.onExpired,
                                error: _vm.onError
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-6" }, [
                          _vm._v(
                            "\n            Proserv will use this information to deal with your enquiry. For more information see our "
                          ),
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-orange-proserv hover:underline",
                              attrs: {
                                href: "https://www.proserv.com/privacy-policy",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Privacy Notice")]
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "flex justify-between items-start" },
                          [
                            _c("div", { staticClass: "text-center" }, [
                              _c("input", {
                                ref: "file",
                                staticClass: "hidden",
                                attrs: {
                                  type: "file",
                                  accept:
                                    "application/msword,.doc, .docx,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.csv,application/pdf,.pdf"
                                },
                                on: { change: _vm.handleFileChange }
                              }),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn-upload w-48 h-13 border-2 border-gray-proserv-upload-border hover:shadow-lg transition",
                                  class: {
                                    "flex flex-col justify-center items-center hover:bg-gray-200":
                                      _vm.upload === null,
                                    "flex items-center relative overflow-hidden bg-orange-proserv text-white hover:border-red-600":
                                      _vm.upload !== null
                                  },
                                  staticStyle: { color: "#303539" },
                                  attrs: {
                                    type: "button",
                                    title:
                                      _vm.upload !== null
                                        ? _vm.upload.fullname
                                        : ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.triggerClickFileInput()
                                    }
                                  }
                                },
                                [
                                  _vm.upload === null
                                    ? [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "text-lg leading-none mb-0.5"
                                          },
                                          [_vm._v("UPLOAD RFQ")]
                                        ),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "text-xs" }, [
                                          _vm._v("10 MB MAX")
                                        ])
                                      ]
                                    : [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fileinfo w-full h-full flex flex-col justify-center items-center transform translate-x-0 transition-transform duration-300"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "text-lg leading-none mb-0.5"
                                              },
                                              [_vm._v(_vm._s(_vm.upload.name))]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "text-xs" },
                                              [_vm._v(_vm._s(_vm.upload.size))]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "fileremove absolute top-0 left-0 w-full h-full text-lg leading-none flex justify-center items-center transform -translate-x-60 transition-transform duration-300 bg-red-600 text-white"
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "h-4.5 w-4.5 mr-1",
                                                attrs: {
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                  fill: "none",
                                                  viewBox: "0 0 24 24",
                                                  stroke: "currentColor"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round",
                                                    "stroke-width": "2",
                                                    d:
                                                      "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", [_vm._v("Remove File")])
                                          ]
                                        )
                                      ]
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("small", { staticClass: "text-gray-700" }, [
                                _vm._v("(Not required)")
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "w-48 h-13 text-orange-proserv bg-gray-proserv-search-box-placeholder transition",
                                class: {
                                  "cursor-not-allowed": _vm.loading,
                                  "hover:bg-gray-proserv-sidebar-search-bg hover:shadow-lg": !_vm.loading
                                },
                                attrs: {
                                  type: "button",
                                  disabled: _vm.loading
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.submit()
                                  }
                                }
                              },
                              [
                                !_vm.loading
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-full h-full flex justify-center items-center"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-lg mr-1" },
                                          [_vm._v("SEND MESSAGE")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "svg",
                                          {
                                            staticClass: "h-4.5 w-4.5",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              fill: "none",
                                              viewBox: "0 0 24 24",
                                              stroke: "currentColor"
                                            }
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                "stroke-linecap": "round",
                                                "stroke-linejoin": "round",
                                                "stroke-width": "2",
                                                d: "M9 5l7 7-7 7"
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "w-full h-full flex justify-center items-center"
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            staticClass:
                                              "animate-spin -ml-1 mr-2 h-4.5 w-4.5 text-white",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              fill: "none",
                                              viewBox: "0 0 24 24"
                                            }
                                          },
                                          [
                                            _c("circle", {
                                              staticClass: "opacity-25",
                                              attrs: {
                                                cx: "12",
                                                cy: "12",
                                                r: "10",
                                                stroke: "currentColor",
                                                "stroke-width": "4"
                                              }
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              staticClass: "opacity-75",
                                              attrs: {
                                                fill: "currentColor",
                                                d:
                                                  "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("span", { staticClass: "text-lg" }, [
                                          _vm._v("SUBMITTING")
                                        ])
                                      ]
                                    )
                              ]
                            )
                          ]
                        )
                      ])
                    : _c(
                        "div",
                        {
                          staticClass:
                            "w-full text-gray-proserv-selected-countries-text text-3xl text-center py-20 leading-normal"
                        },
                        [
                          _c("img", {
                            staticClass:
                              "absolute top-0 left-0 w-full h-full object-cover",
                            attrs: {
                              src:
                                "/app/themes/proserv/dist/images/contact-message-sent-bg-image.png",
                              alt: "Message Sent Background Image"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "success-msg text-lg lg:text-2xl relative z-10"
                            },
                            [
                              _vm._v(
                                "\n            Your message has successfully sent!"
                              ),
                              _c("br"),
                              _vm._v(
                                "\n            One of our sales representatives listed beside will be contacting you shortly regarding your recent inquiry.\n          "
                              )
                            ]
                          )
                        ]
                      )
                ]
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }