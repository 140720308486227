//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EventBus from '@/util/event-bus'
import { debounce } from 'lodash'

export default {
  name: 'SearchBox',
  props: {
    show: Boolean,
  },
  data () {
    return {
      searchQuery: null,
      withFilters: true,
    }
  },
  watch: {
    searchQuery: debounce(function () {
      this.filterChanged()
    }, 400),
  },
  methods: {
    filterChanged () {
      EventBus.$emit('filterChanged', { q: this.searchQuery, withFilters: this.withFilters })
    },
    showSelectedModal () {
      EventBus.$emit('showSelectedModal')
    },
    showSidebar () {
      document.getElementById('sidebar').classList.remove('-translate-x-full')
      document.body.style.overflow = 'hidden'
    },
  },
}
