var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _vm.hasLeftSide
      ? _c(
          "div",
          {
            staticClass:
              "sidebar bg-gray-proserv-sidebar-bg h-full w-full lg:w-72 xl:w-80 2xl:w-96 3xl:w-100 4xl:w-108 pb-6 flex-shrink-0 fixed top-0 left-0 right-0 bottom-0 lg:relative z-20 lg:z-10 transform lg:transform-none -translate-x-full lg:translate-x-0 transition-transform duration-300 overflow-y-auto lg:overflow-hidden",
            attrs: { id: "sidebar" }
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "h-15 md:h-20 2xl:h-24 flex lg:hidden justify-end items-center px-4 sm:px-6 xl:px-8 3xl:px-10"
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "-mr-2 p-1 cursor-pointer",
                    attrs: { id: "closeSidebar" },
                    on: {
                      click: function($event) {
                        return _vm.closeSidebar()
                      }
                    }
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass:
                          "h-8 w-8 text-white hover:text-gray-100 pointer-events-none",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          fill: "none",
                          viewBox: "0 0 24 24",
                          stroke: "currentColor"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            "stroke-linecap": "round",
                            "stroke-linejoin": "round",
                            "stroke-width": "2",
                            d: "M6 18L18 6M6 6l12 12"
                          }
                        })
                      ]
                    )
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
              _vm.step === 1
                ? _c(
                    "div",
                    {
                      key: "filters",
                      staticClass:
                        "flex flex-col justify-start w-full text-white pt-3 3xl:pt-4 pb-8",
                      attrs: { id: "sidebarContent" }
                    },
                    [
                      _c("CollapseItem", {
                        staticClass: "hidden",
                        attrs: { title: "Countries", collapsed: true },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "w-full h-auto pb-4 relative"
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "addition-content w-full px-4 sm:px-6 xl:px-8 4xl:px-10 pb-4"
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.trim",
                                                value: _vm.searchQuery,
                                                expression: "searchQuery",
                                                modifiers: { trim: true }
                                              }
                                            ],
                                            staticClass:
                                              "w-full text-white bg-gray-proserv-sidebar-search-bg placeholder-gray-proserv-sidebar-search-placeholder text-md p-4 outline-none focus:outline-none",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Search Countries"
                                            },
                                            domProps: {
                                              value: _vm.searchQuery
                                            },
                                            on: {
                                              focus: function($event) {
                                                return _vm.toggleSearching(true)
                                              },
                                              blur: [
                                                function($event) {
                                                  return _vm.toggleSearching(
                                                    false
                                                  )
                                                },
                                                function($event) {
                                                  return _vm.$forceUpdate()
                                                }
                                              ],
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.searchQuery = $event.target.value.trim()
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.filteredCountries.length
                                        ? [
                                            _c(
                                              "ul",
                                              {
                                                staticClass:
                                                  "inner-content w-full item-list pl-0 text-md"
                                              },
                                              _vm._l(
                                                _vm.filteredCountries,
                                                function(country) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      key: country.code,
                                                      staticClass:
                                                        "item text-sm xl:text-2sm w-full flex justify-between items-center px-4 sm:px-6 xl:px-8 4xl:px-10 py-4 transition cursor-pointer leading-7",
                                                      class: {
                                                        selected: _vm.newSelectedCountries.includes(
                                                          country.code
                                                        )
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.preventDefault()
                                                          return _vm.toggleSelectCountry(
                                                            country
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "flex-grow truncate"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(country.name)
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "flex-shrink"
                                                        },
                                                        [
                                                          _vm.newSelectedCountries.includes(
                                                            country.code
                                                          )
                                                            ? _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "h-6 w-6 ml-2",
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    fill:
                                                                      "none",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    stroke:
                                                                      "currentColor"
                                                                  }
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      "stroke-linecap":
                                                                        "round",
                                                                      "stroke-linejoin":
                                                                        "round",
                                                                      "stroke-width":
                                                                        "2",
                                                                      d:
                                                                        "M6 18L18 6M6 6l12 12"
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            : _c(
                                                                "svg",
                                                                {
                                                                  staticClass:
                                                                    "h-6 w-6 ml-2",
                                                                  attrs: {
                                                                    xmlns:
                                                                      "http://www.w3.org/2000/svg",
                                                                    fill:
                                                                      "none",
                                                                    viewBox:
                                                                      "0 0 24 24",
                                                                    stroke:
                                                                      "currentColor"
                                                                  }
                                                                },
                                                                [
                                                                  _c("path", {
                                                                    attrs: {
                                                                      "stroke-linecap":
                                                                        "round",
                                                                      "stroke-linejoin":
                                                                        "round",
                                                                      "stroke-width":
                                                                        "2",
                                                                      d:
                                                                        "M12 4v16m8-8H4"
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          ]
                                        : (_vm.isSearching &&
                                            _vm.searchQuery.length) ||
                                          _vm.searchQuery.length
                                        ? [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "inner-content px-4 sm:px-6 xl:px-8 4xl:px-10 py-4"
                                              },
                                              [_vm._v("No results found.")]
                                            )
                                          ]
                                        : [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "inner-content px-4 sm:px-6 xl:px-8 4xl:px-10 py-4"
                                              },
                                              [
                                                _vm._v(
                                                  "No selected country. Please select at least a country by searching in the text field above."
                                                )
                                              ]
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          814902488
                        )
                      }),
                      _vm._v(" "),
                      _c("CollapseItem", {
                        attrs: {
                          loading: _vm.categories.loading,
                          title: "Products and Services"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "w-full h-full pb-4" },
                                    [
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "inner-content w-full item-list pl-0 text-md"
                                        },
                                        _vm._l(_vm.categories.data, function(
                                          category
                                        ) {
                                          return _c(
                                            "li",
                                            {
                                              key: category.id,
                                              staticClass:
                                                "text-sm xl:text-2sm item w-full flex justify-between items-center px-4 sm:px-6 xl:px-8 4xl:px-10 py-4 transition cursor-pointer leading-7",
                                              class: {
                                                selected:
                                                  _vm.categories.selected.filter(
                                                    function(item) {
                                                      return (
                                                        item.id === category.id
                                                      )
                                                    }
                                                  ).length > 0
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleSelectCategory(
                                                    category
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("span", {
                                                staticClass:
                                                  "flex-grow truncate",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    category.name
                                                  )
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "item-checkbox flex-shrink"
                                                },
                                                [
                                                  _vm.categories.selected.filter(
                                                    function(item) {
                                                      return (
                                                        item.id === category.id
                                                      )
                                                    }
                                                  ).length > 0
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "border border-4 border-orange-proserv-text flex items-center justify-center",
                                                          staticStyle: {
                                                            height: "22px",
                                                            width: "22px",
                                                            "border-width":
                                                              "3px"
                                                          }
                                                        },
                                                        [
                                                          _c("div", {
                                                            staticClass:
                                                              "w-full h-full p-1 bg-orange-proserv-text",
                                                            staticStyle: {
                                                              height: "60%",
                                                              width: "60%"
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _c("div", {
                                                        staticClass:
                                                          "border border-4 border-gray-rightside ml-2",
                                                        staticStyle: {
                                                          height: "22px",
                                                          width: "22px",
                                                          "border-width": "3px"
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          2209718340
                        )
                      }),
                      _vm._v(" "),
                      _c("CollapseItem", {
                        attrs: {
                          loading: _vm.certifications.loading,
                          title: "Certifications"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "content",
                              fn: function() {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "w-full h-full pb-4" },
                                    [
                                      _c(
                                        "ul",
                                        {
                                          staticClass:
                                            "inner-content w-full item-list pl-0 text-md"
                                        },
                                        _vm._l(
                                          _vm.certifications.data,
                                          function(certification) {
                                            return _c(
                                              "li",
                                              {
                                                key: certification.id,
                                                staticClass:
                                                  "text-sm xl:text-2sm item w-full flex justify-between items-center px-4 sm:px-6 xl:px-8 4xl:px-10 py-4 transition cursor-pointer leading-7",
                                                class: {
                                                  selected:
                                                    _vm.certifications.selected.filter(
                                                      function(item) {
                                                        return (
                                                          item.id ===
                                                          certification.id
                                                        )
                                                      }
                                                    ).length > 0
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.toggleSelectCertification(
                                                      certification
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "flex-grow truncate",
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      certification.name
                                                    )
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "item-checkbox flex-shrink"
                                                  },
                                                  [
                                                    _vm.certifications.selected.filter(
                                                      function(item) {
                                                        return (
                                                          item.id ===
                                                          certification.id
                                                        )
                                                      }
                                                    ).length > 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "border border-4 border-orange-proserv-text flex items-center justify-center",
                                                            staticStyle: {
                                                              height: "22px",
                                                              width: "22px",
                                                              "border-width":
                                                                "3px"
                                                            }
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "w-full h-full p-1 bg-orange-proserv-text",
                                                              staticStyle: {
                                                                height: "60%",
                                                                width: "60%"
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      : _c("div", {
                                                          staticClass:
                                                            "border border-4 border-gray-rightside ml-2",
                                                          staticStyle: {
                                                            height: "22px",
                                                            width: "22px",
                                                            "border-width":
                                                              "3px"
                                                          }
                                                        })
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3223961009
                        )
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "w-full h-full pb-4" }, [
                        _c(
                          "ul",
                          {
                            staticClass:
                              "inner-content w-full item-list pl-0 text-md"
                          },
                          _vm._l(_vm.availabilities.data, function(
                            availability
                          ) {
                            return _c(
                              "li",
                              {
                                key: availability.id,
                                staticClass:
                                  "text-sm xl:text-2sm item w-full flex justify-between items-center px-4 sm:px-6 xl:px-8 4xl:px-10 py-4 transition cursor-pointer leading-7",
                                class: {
                                  selected:
                                    _vm.availabilities.selected.filter(function(
                                      item
                                    ) {
                                      return item.id === availability.id
                                    }).length > 0
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.toggleSelectAvailability(
                                      availability
                                    )
                                  }
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "flex-grow truncate",
                                  domProps: {
                                    innerHTML: _vm._s(availability.label)
                                  }
                                }),
                                _vm._v(" "),
                                _c("span", { staticClass: "flex-shrink" }, [
                                  _vm.availabilities.selected.filter(function(
                                    item
                                  ) {
                                    return item.id === availability.id
                                  }).length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "border border-4 border-orange-proserv-text flex items-center justify-center",
                                          staticStyle: {
                                            height: "22px",
                                            width: "22px",
                                            "border-width": "3px"
                                          }
                                        },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "w-full h-full p-1 bg-orange-proserv-text",
                                            staticStyle: {
                                              height: "60%",
                                              width: "60%"
                                            }
                                          })
                                        ]
                                      )
                                    : _c("div", {
                                        staticClass:
                                          "border border-4 border-gray-rightside ml-2",
                                        staticStyle: {
                                          height: "22px",
                                          width: "22px",
                                          "border-width": "3px"
                                        }
                                      })
                                ])
                              ]
                            )
                          }),
                          0
                        )
                      ])
                    ],
                    1
                  )
                : _vm.step === 2
                ? _c(
                    "div",
                    {
                      key: "selections",
                      staticClass:
                        "hidden flex flex-col justify-start w-full text-white p-4 sm:p-6 xl:p-8 4xl:p-10",
                      attrs: { id: "sidebarContent" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-base uppercase pb-5 border-b border-gray-proserv-sidebar-divider"
                        },
                        [_vm._v("REGIONS SELECTED")]
                      ),
                      _vm._v(" "),
                      this.regionsSelected.loading !== true
                        ? _c(
                            "div",
                            _vm._l(this.$parent.regionsSelectedData, function(
                              region,
                              key
                            ) {
                              return _c(
                                "div",
                                {
                                  key: key,
                                  staticClass:
                                    "py-6 border-b border-gray-proserv-sidebar-divider"
                                },
                                [
                                  _c("span", {
                                    staticClass: "block text-1.5xl mb-4",
                                    staticStyle: { color: "#8f8f90" },
                                    domProps: { innerHTML: _vm._s(region.name) }
                                  }),
                                  _vm._v(" "),
                                  _vm._l(region.agents, function(agent) {
                                    return _c(
                                      "div",
                                      { key: agent.id, staticClass: "mb-2" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "text-base mb-4",
                                            staticStyle: { color: "#f3f3f3" }
                                          },
                                          [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(agent.name)
                                              }
                                            }),
                                            _vm._v(" | "),
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(agent.job)
                                              }
                                            })
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "text-base" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#858687"
                                                }
                                              },
                                              [_vm._v(_vm._s(agent.phone))]
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            }),
                            0
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-center items-center mt-5"
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "animate-spin -ml-1 mr-3 h-6 w-6 text-white",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "none",
                                    viewBox: "0 0 24 24"
                                  }
                                },
                                [
                                  _c("circle", {
                                    staticClass: "opacity-25",
                                    attrs: {
                                      cx: "12",
                                      cy: "12",
                                      r: "10",
                                      stroke: "currentColor",
                                      "stroke-width": "4"
                                    }
                                  }),
                                  _vm._v(" "),
                                  _c("path", {
                                    staticClass: "opacity-75",
                                    attrs: {
                                      fill: "#fff",
                                      d:
                                        "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "text-gray-proserv-header-component"
                                },
                                [_vm._v("Loading data...")]
                              )
                            ]
                          ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "mt-10",
                          staticStyle: { color: "#82898e" }
                        },
                        [
                          _vm._v(
                            "\n          NOTE: Some services are dependent on region selected.\n        "
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "hidden lg:flex px-8 pb-8",
                class: {
                  "justify-end": _vm.step === 1,
                  "justify-start": _vm.step === 1
                },
                attrs: { id: "sidebarNav" }
              },
              [
                _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                  _vm.step === 1
                    ? _c(
                        "a",
                        {
                          staticClass: "text-2xs text-orange-proserv",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              return _vm.showSelectedModal()
                            }
                          }
                        },
                        [_vm._v("\n          VIEW MY SELECTIONS\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.step === 2
                    ? _c(
                        "a",
                        {
                          staticClass:
                            "text-2xs text-orange-proserv pt-4 sm:pt-6 xl:pt-8 4xl:pt-10",
                          attrs: { href: "javascript:;" },
                          on: {
                            click: function($event) {
                              return _vm.backToStep(1)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "flex justify-start items-center" },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass: "h-5 w-5 mr-2",
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    fill: "none",
                                    viewBox: "0 0 24 24",
                                    stroke: "currentColor"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d: "M15 19l-7-7 7-7"
                                    }
                                  })
                                ]
                              ),
                              _vm._v(" "),
                              _c("span", [_vm._v("GO BACK")])
                            ]
                          )
                        ]
                      )
                    : _vm._e()
                ])
              ],
              1
            ),
            _vm._v(" "),
            _vm.step === 1
              ? _c("div", { staticClass: "lg:hidden px-4 sm:px-6 py-8" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "px-2 h-9 flex justify-center items-center ml-auto bg-orange-proserv text-gray-proserv-search-box-placeholder rounded",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.closeSidebar()
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "text-sm" }, [
                        _vm._v("Submit Filter")
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }