var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.error
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "w-full h-full bg-gray-200 flex justify-center items-center"
          },
          [
            _c(
              "svg",
              {
                staticClass: "w-12",
                staticStyle: { fill: "#000000" },
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  x: "0px",
                  y: "0px",
                  width: "100",
                  height: "100",
                  viewBox: "0 0 172 172"
                }
              },
              [
                _c(
                  "g",
                  {
                    staticStyle: { "mix-blend-mode": "normal" },
                    attrs: {
                      fill: "none",
                      "fill-rule": "nonzero",
                      stroke: "none",
                      "stroke-width": "1",
                      "stroke-linecap": "butt",
                      "stroke-linejoin": "miter",
                      "stroke-miterlimit": "10",
                      "stroke-dasharray": "",
                      "stroke-dashoffset": "0",
                      "font-family": "none",
                      "font-weight": "none",
                      "font-size": "none",
                      "text-anchor": "none"
                    }
                  },
                  [
                    _c("path", {
                      attrs: { d: "M0,172v-172h172v172z", fill: "none" }
                    }),
                    _c("g", { attrs: { fill: "#9ca3af" } }, [
                      _c("path", {
                        attrs: {
                          d:
                            "M10.28641,6.84641c-1.39982,0.00037 -2.65984,0.84884 -3.18658,2.14577c-0.52674,1.29693 -0.21516,2.7837 0.78799,3.76001l151.36,151.36c0.86281,0.89867 2.14404,1.26068 3.34956,0.94641c1.20552,-0.31427 2.14696,-1.2557 2.46122,-2.46122c0.31427,-1.20552 -0.04774,-2.48675 -0.94641,-3.34956l-11.32781,-11.32781h12.33563v-123.84h-136.17562l-16.19219,-16.19219c-0.64765,-0.66575 -1.53698,-1.04135 -2.46578,-1.04141zM6.88,24.08v123.84h126.27219l-6.88,-6.88h-112.51219v-26.02172l44.73344,-41.75703l-4.86437,-4.86438l-39.86906,37.20844v-74.64531h2.43219l-6.88,-6.88zM35.82437,30.96h122.41563v94.89563l-38.84781,-38.84781c-1.34504,-1.34504 -3.51933,-1.34504 -4.86437,0l-11.32781,11.32781zM120.4,51.6c-5.69958,0 -10.32,4.62042 -10.32,10.32c0,5.69958 4.62042,10.32 10.32,10.32c5.69958,0 10.32,-4.62042 10.32,-10.32c0,-5.69958 -4.62042,-10.32 -10.32,-10.32zM116.96,94.30437l41.28,41.28v5.45563h-12.33563l-37.84,-37.84zM93.3839,108.15172l-6.37609,6.3761c-1.34504,1.34504 -1.34504,3.51933 0,4.86437c1.34504,1.34504 3.51933,1.34504 4.86437,0l6.3761,-6.37609z"
                        }
                      })
                    ])
                  ]
                )
              ]
            )
          ]
        )
      ])
    : _c("div", { staticClass: "relative" }, [
        _vm.loading
          ? _c(
              "div",
              {
                staticClass:
                  "w-full h-full bg-gray-200 flex justify-center items-center"
              },
              [
                _c(
                  "svg",
                  {
                    staticClass: "animate-spin h-6 w-6 text-white",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24"
                    }
                  },
                  [
                    _c("circle", {
                      staticClass: "opacity-25",
                      attrs: {
                        cx: "12",
                        cy: "12",
                        r: "10",
                        stroke: "currentColor",
                        "stroke-width": "4"
                      }
                    }),
                    _vm._v(" "),
                    _c("path", {
                      staticClass: "opacity-75",
                      attrs: {
                        fill: "currentColor",
                        d:
                          "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      }
                    })
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.loading,
              expression: "!loading"
            }
          ],
          staticClass:
            "absolute top-0 right-0 bottom-0 left-0 w-full h-full object-cover object-center",
          attrs: { src: _vm.src, alt: _vm.alt },
          on: {
            load: function($event) {
              return _vm.init()
            },
            error: function($event) {
              return _vm.initError()
            }
          }
        }),
        _vm._v(" "),
        _vm.logoSrc
          ? _c(
              "div",
              {
                staticClass: "absolute top-2 right-2 w-1/4",
                staticStyle: { "max-width": "96px" }
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loading,
                      expression: "!loading"
                    }
                  ],
                  staticClass: "max-w-full h-auto",
                  attrs: {
                    src: _vm.logoSrc,
                    alt: _vm.logoAlt ? _vm.logoAlt : ""
                  }
                })
              ]
            )
          : _vm._e()
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }