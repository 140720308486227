export const setHeight = (step = null) => {
  console.log(step)
  let height = 0
  if (typeof (window.innerHeight) == 'number') {
    // Non-IE
    height = window.innerHeight
  } else if (document.documentElement && document.documentElement.clientHeight) {
    // IE 6+ in 'standards compliant mode'
    height = document.documentElement.clientHeight
  } else if (document.body && document.body.clientHeight) {
    //IE 4 compatible
    height = document.body.clientHeight
  }

  const screenHeight = height
  const headerHeight = document.getElementById('header').offsetHeight
  const screenContentHeight = screenHeight - headerHeight

  if (step === 1) {
    const mapHeight = document.querySelector('#maps > div').offsetHeight
    document.getElementById('content').style.height = mapHeight + 'px'
    document.getElementById('content').style.minHeight = mapHeight + 'px'
    return;
  } else {
    document.getElementById('content').style.height = null
  }

  if (document.body.classList.contains('desktop-wide')) {
    if (step === 2 || step === 3) {
      const sidebarNavHeight = document.getElementById('sidebarNav').offsetHeight
      const offsetSidebarHeight = document.getElementById('sidebarContent').offsetHeight + sidebarNavHeight

      let mainContentHeight = 0
      const mainContentEl = document.getElementById('mainContent')
      if (mainContentEl) {
        const productListEl = mainContentEl.querySelector('.product-list')
        if (productListEl) {
          const stepsWrapperHeight = mainContentEl.querySelector('.steps-wrapper').offsetHeight
          const infiniteHeight = mainContentEl.querySelector('.infinite-loading-container') ? mainContentEl.querySelector('.infinite-loading-container').offsetHeight : 0
          mainContentHeight = productListEl.offsetHeight + stepsWrapperHeight + infiniteHeight
        } else {
          mainContentHeight = mainContentEl.offsetHeight
        }
      }

      if (screenContentHeight > offsetSidebarHeight && screenContentHeight > mainContentHeight) {
        document.getElementById('content').style.minHeight = screenContentHeight + 'px'
      } else {
        document.getElementById('content').style.minHeight = Math.max(offsetSidebarHeight, mainContentHeight) + 'px'
      }

      return;
    }

    if (step === 4) {
      const stepsHeight = document.querySelector('.steps-wrapper').offsetHeight
      const contentHeight = document.getElementById('contactUs').offsetHeight
      document.getElementById('content').style.minHeight = (stepsHeight + contentHeight) + 'px'
      // document.getElementById('content').style.minHeight = null
      return
    }
  } else {
    document.getElementById('content').style.minHeight = 'auto'
  }
}
